<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
</head>

<body>
    <section>
        <div class="bussinesscard"
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;padding-bottom:2.5rem;background-color: white;">
            <div>
                <img src="./assets/images/1023/BG.jpg" style="width:100%;">
            </div>
            <div class="row" style="display:flex;margin:50px 30px;margin-bottom:0px !important">
                <div *ngIf="contactInfo?.profile_picture" class="col">
                    <img [src]="contactInfo?.profile_picture" style="width:120px;height:120px;object-fit: cover;">
                </div>
                <div class="col" style="margin-left:20px;text-align:left;margin: auto;">
                    <h2
                        style="margin:0px;font-size:25px;color:#161616;font-family: 'Poppins', sans-serif;line-height: 1;">
                        {{contactInfo?.first_name}} {{contactInfo?.middle_name}} <span
                            style="color:#27a2f3;">{{contactInfo?.last_name}}</span></h2>
                    <P style="margin:0px;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;line-height: normal;">
                        {{contactInfo?.designation}}</P>
                    <hr
                        style="border-bottom:2px solid #161616;width:85px;margin:0px;margin-top:8px;margin-bottom: 8px;">
                    <p *ngIf="contactInfo?.description"
                        style="margin:0px;font-size:13px;color:#4d4d4f;font-weight:100;font-family: 'Montserrat', sans-serif;line-height: 1.5;">
                        {{contactInfo?.description}}</p>
                </div>

            </div>
            <!-- datas -->
            <div style="margin-left:15px;margin-right:30px;margin-top:2rem;">
                <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;">
                    <div style="display:flex;align-items:center;flex-shrink:0;">
                        <img src="./assets/images/1023/ph_new.png" style="width:25px;height:25px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:15px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.phone_number_1}}</span>
                </a>
                <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;">
                    <div style="display:flex;align-items:center;flex-shrink:0;">
                        <img src="./assets/images/1023/email_new.png" style="width:25px;height:25px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:15px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
                </a>
                <div *ngIf="contactInfo?.address"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;">
                    <div style="display:flex;align-items:center;flex-shrink:0;">
                        <img src="./assets/images/1023/location_new.png" style="width:25px;height:25px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
                </div>
                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;">
                    <div style="display:flex;align-items:center;flex-shrink:0;">
                        <img src="./assets/images/1023/web_new.png" style="width:25px;height:25px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:13px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.website}}</span>
                </a>

            </div>

        </div>

    </section>
</body>

</html>