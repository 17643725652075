<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">


<section>
    <div class="businesscard"
        style="background-color: white;min-width: 250px; max-width:400px;height:auto;margin:auto;box-shadow: 2px 5px 15px 0px #17161650;">

        <!-- Profile image -->
        <!-- <div  style="background-color:#5cb024;">
            <img [src]="contactInfo?.profile_picture" style="width: 100%;object-fit: contain;">
        </div>
        <img src="./assets/images/5962/angle_green.png" style="position: absolute;top:230px;width:inherit;"> -->
        <div style="position:relative;">
            <img [src]="contactInfo?.profile_picture"
                style="background-color: #5cb024; min-height: 250px;max-height:350px; width: 100%;object-fit: cover;">
            <img src="./assets/images/5962/angle_green.png" style="position: absolute;bottom: -1px;">

        </div>
        <!-- Profie data -->
        <div style="margin-left:30px;margin-top:-20px; position: relative;">
            <p
                style="font-size:28px;margin:0px;color:#3b3a3a;font-weight:600;font-family: 'Poppins', sans-serif;line-height: 1;">
                {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
            <p
                style="font-size:20px;margin:0px;color:#5cb024;font-family: 'Poppins', sans-serif;font-weight: 300;line-height: 1;">
                {{contactInfo?.designation}}</p>
        </div>
        <!-- About me -->
        <div style="padding-left:30px;padding-right:20px;margin-top:1.5rem;margin-bottom:1.5rem;">
            <p style="color:#4d4d4f;font-size:17px;font-weight:bold;font-family: 'Varela Round', sans-serif;font-weight:400;font-family: 'Poppins', sans-serif;"
                *ngIf="contactInfo?.description_title">{{contactInfo?.description_title}}</p>
            <p style="color:#4d4d4f;line-height:1.5;font-size:17px;font-weight:100;font-family: 'Montserrat', sans-serif;"
                *ngIf="contactInfo?.description">{{contactInfo?.description}}</p>
        </div>

        <!-- company logo -->
        <div style="margin-left:30px;object-fit:contain;">
            <img [src]="contactInfo?.company_logo"
                style="max-height:80px;max-width:180px;object-fit: contain;width: auto;"
                *ngIf="contactInfo?.company_logo">
            <p style="font-size:17px;margin-top:7px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight: 400;"
                *ngIf="contactInfo?.company">
                {{contactInfo.company}}</p>
        </div>

        <!-- datas -->
        <div style="margin-top:35px;">
            <a style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1">
                <fa-icon [icon]="iconPhone"
                    style="padding:7px;width:20px;height:20px;justify-content: center;display: flex;align-items: center;">
                </fa-icon>
                <span
                    style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                    {{contactInfo?.phone_number_1}}</span>

            </a>
            <a style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                *ngIf="contactInfo?.phone_number_2" [href]="'tel:'+ contactInfo?.phone_number_2">
                <fa-icon [icon]="iconTelephone"
                    style="padding:7px;width:20px;height:20px;justify-content: center;display: flex;align-items: center;">
                </fa-icon>
                <span
                    style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                    {{contactInfo?.phone_number_2}}</span>

            </a>
            <a style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email">
                <fa-icon [icon]="iconEmail"
                    style="padding:7px;width:20px;height:20px;justify-content: center;display: flex;align-items: center;">
                </fa-icon>
                <span
                    style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                    {{contactInfo?.email}}</span>
            </a>
            <a *ngIf="contactInfo?.website" [href]="contactInfo?.website"
                style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                <fa-icon [icon]="iconWeb"
                    style="padding:7px;width:20px;height:20px;justify-content: center;display: flex;align-items: center;">
                </fa-icon>
                <span
                    style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:400;">{{contactInfo?.website}}</span>
            </a>
            <div style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                *ngIf="contactInfo?.address">
                <fa-icon [icon]="iconLocation"
                    style="padding:7px;width:20px;height:20px;justify-content: center;display: flex;align-items: center;">
                </fa-icon>
                <span
                    style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                    {{contactInfo?.address}}
                </span>
            </div>


        </div>

        <div style="width:100%;background-color:#5cb024;max-height:125px;padding:30px 0px;">
            <p *ngIf="contactInfo?.twitter"
                style="align-items: center;color:white;font-size:13px;font-family: 'Poppins', sans-serif;text-align: center;margin:0px;line-height: 2;">
                @{{contactInfo?.twitter}}</p>
            <div class="row" style="display:flex;justify-content: center;align-items:center;">
                <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.facebook">
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook"
                        style="background-color:white;padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconFacebook" class="smmIcon"></fa-icon>
                    </a>
                </div>
                <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.instagram">
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram"
                        style="background-color:white;padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconInstagram" class="smmIcon"></fa-icon>
                    </a>
                </div>
                <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.twitter">
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter"
                        style="background-color:white;padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconTwitter" class="smmIcon"></fa-icon>
                    </a>
                </div>
                <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.pinterest">
                    <a [href]="'https://pinterest.com/'+contactInfo?.pinterest"
                        style="background-color:white;padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconPinterest" class="smmIcon"></fa-icon>
                    </a>
                </div>
                <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.youtube">
                    <a [href]="'https://youtube.com/'+contactInfo?.youtube"
                        style="background-color:white;padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconYouTube" class="smmIcon"></fa-icon>
                    </a>
                </div>
                <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.linkedin">
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin"
                        style="background-color:white;padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconLinkedIn" class="smmIcon"></fa-icon>
                    </a>
                </div>
                <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.whatsapp">
                    <a [href]="'https://wa.me/'+contactInfo?.whatsapp"
                        style="background-color:white;padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconWhatsapp" class="smmIcon"></fa-icon>
                    </a>
                </div>
            </div>
        </div>

    </div>

</section>