<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">


    <style>
        .grad {
            background-image: -webkit-linear-gradient(-250deg, #f14168 -10%, #f14168 100%);
            min-height: 300px;
            clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 50%);
        }

        .profile {
            position: absolute;
            width: 50%;
            left: 50%;
            top: 100px;
            transform: translate(-50%);
            text-align: center;
        }

        .grad-bottom {
            background-image: -webkit-linear-gradient(-250deg, #f14168 -10%, #f14168 100%);
            min-height: 235px;
            clip-path: polygon(0 0%, 100% 50%, 100% 100%, 0 100%);
        }
    </style>
</head>

<body  style="background-color: transparent !important;">
    <section>
        <div class="businesscard"
            style="width: 100%;max-width: 400px; height:auto;margin:auto;box-shadow: 2px 5px 15px 0px #17161650;position: relative;background-color:white;">
            <div style="position:relative">
                <div class="grad" style="width:100%;position: relative;">

                </div>
                <!-- Profile image -->
                <img src="./assets/images/5964/shape.png" style="position:absolute;top:200px;width:100px;">

                <div style="position:absolute;top:40%;left:29%;" *ngIf="contactInfo?.profile_picture">
                    <img [src]="contactInfo?.profile_picture" style="border-radius:50%;width: 150px;height: 150px;object-fit: cover;">
                </div>

            </div>
            <!-- Profie data -->
            <div style="text-align:center;margin-top:25px;margin-bottom:30px;padding: 0px 50px;">
                <p
                    style="font-size:28px;margin:0px;color:#5e5e5e;font-weight:600;font-family: 'Poppins', sans-serif;line-height: 1;">
                    {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="font-size:20px;margin:0px;color:#f14168;font-family: 'Poppins', sans-serif;font-weight: 500;">
                    {{contactInfo?.designation}}</p>
            </div>
             <!-- About me -->
             <div style="padding-left:30px;padding-right:20px;margin-top:1.5rem;margin-bottom:1.5rem;">
                <p style="color:#4d4d4f;font-size:17px;font-weight:bold;font-family: 'Varela Round', sans-serif;font-weight:400;font-family: 'Poppins', sans-serif;"
                    *ngIf="contactInfo?.description_title">{{contactInfo?.description_title}}</p>
                <p style="color:#4d4d4f;line-height:1.5;font-size:17px;font-weight:100;font-family: 'Montserrat', sans-serif;"
                    *ngIf="contactInfo?.description">{{contactInfo?.description}}</p>
            </div>
            <!-- company logo -->
            <div style="margin-left:30px;object-fit:contain;margin-bottom:1.5rem;">
                <img [src]="contactInfo?.company_logo" style="max-height:80px;max-width:180px;object-fit: contain;width: auto;" *ngIf="contactInfo?.company_logo">
                <p style="font-size:17px;margin-top:7px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight: 400;"
                    *ngIf="contactInfo?.company">
                    {{contactInfo.company}}</p>
            </div>


            <!-- datas -->
            <div>
                <div *ngIf="contactInfo?.address"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;">
                    <fa-icon [icon]="iconLocation"
                        style="color: white; background-color:#f14168;padding:13px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                        {{contactInfo?.address}}</span>
                </div>
                <a *ngIf="contactInfo?.phone_number_1"  [href]="'tel:'+ contactInfo?.phone_number_1"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;">
                    <fa-icon [icon]="iconPhone"
                        style="color: white; background-color:#f14168;padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">{{contactInfo?.phone_number_1}}</span>

                </a>
                <a style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                    *ngIf="contactInfo?.phone_number_2" [href]="'tel:'+ contactInfo?.phone_number_2">
                    <fa-icon [icon]="iconTelephone"
                    style="color: white; background-color:#f14168;padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                        {{contactInfo?.phone_number_2}}</span>

                </a>
                <a *ngIf="contactInfo?.email"  [href]="'mailto:'+ contactInfo?.email"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;">
                    <fa-icon [icon]="iconEmail"
                        style="color: white; background-color:#f14168;padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">{{contactInfo?.email}}</span>
                </a>
                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website"
                style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                <fa-icon [icon]="iconWeb"
                style="color: white; background-color:#f14168;padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                </fa-icon>
                <span
                    style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:400;">{{contactInfo?.website}}</span>
            </a>
            </div>
            <img src="./assets/images/5964/shape.png"
                style="position:absolute;top:450px;transform: rotate(180deg);float:right;right:0px;width:100px;">
            <div class="grad-bottom" style="width:100%;display: flex;justify-content: center;align-items: center;">
                <!-- icons -->
                <div >
                    <p *ngIf="contactInfo?.twitter"
                    style="align-items: center;color:white;font-size:13px;font-family: 'Poppins', sans-serif;text-align: center;margin:0px;line-height: 2;">
                    @{{contactInfo?.twitter}}</p>
                    <div class="row gap-3" style="display:flex;margin:25px;margin-bottom:-80px;">
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconFacebook"></fa-icon>
                    </a>
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconInstagram"></fa-icon>
                    </a>
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconTwitter"></fa-icon>
                    </a>
                    <a [href]="'https://pinterest.com/'+contactInfo?.pinterest" *ngIf="contactInfo?.pinterest"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconPinterest"></fa-icon>
                    </a>
                    <a [href]="'https://youtube.com/'+contactInfo?.youtube" *ngIf="contactInfo?.youtube"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconYouTube"></fa-icon>
                    </a>
                    <a [href]="'https://linked.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconLinkedIn"></fa-icon>
                    </a>
                    <a [href]="'https://wa.me/'+contactInfo?.whatsapp" *ngIf="contactInfo?.whatsapp"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconWhatsapp"></fa-icon>
                    </a>
                    
                </div>


            </div>
            </div>

        </div>




    </section>

</body>

</html>