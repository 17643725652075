import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from "@sentry/angular";

Sentry.init({
    dsn: "https://b2c902e7306b4393ad80716ef3ddd32a@o185609.ingest.sentry.io/6573396",
    integrations: [
        new BrowserTracing({
            tracingOrigins: ["localhost", environment.shareUrl],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
