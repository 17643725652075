<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">

</head>

<body>
    <section style="background-color:white;">

        <!-- profile image -->
        <div class="" style="min-width: 250px; max-width:400px !important;height:auto;margin:auto;">

            <!--  Profile datas -->
            <div style="position:relative;top:0px;left:0px;">
                <img src="./assets/images/1022/bg.png" style="position:relative;top:0px;left:0px;width:100%;">
                <div class="row" style="display: flex;position:absolute;top:100px;left:30px;">
                    <div *ngIf="contactInfo?.profile_picture" class="col"
                        style="display:flex;margin:auto;border:2px solid #dbdadb;padding:2px;border-radius:100%;display:flex;justify-content: center;width:fit-content;">
                        <img [src]="contactInfo?.profile_picture" style="width:130px;height:130px;border-radius:100%;object-fit: cover;">
                    </div>
                    <div class="col"
                        style="text-align:center;display: block;align-self: center;margin-left:20px;line-height: 1.3;">
                        <p
                            style="font-size:25px;margin:0px;font-weight:600;color:white;font-family: 'Poppins', sans-serif;text-align: initial;">
                            {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                        <p
                            style="font-size:17px;margin:0px;color:white;font-family: 'Poppins', sans-serif;text-align: initial;">
                            {{contactInfo?.designation}}</p>
                    </div>
                </div>

            </div>

            <!-- About me -->
            <div style="margin-left:30px;margin-right:33px;margin-bottom: 2rem;margin-top:-20px;">
                <p *ngIf="contactInfo?.description_title"
                    style="color:#4d4d4f;font-size:20px;font-family: 'Poppins', sans-serif;font-weight:300;margin-bottom:12px;">
                    {{contactInfo?.description_title}}</p>
                <p *ngIf="contactInfo?.description"
                    style="color:#4d4d4f;line-height:1.5;font-size:14px;font-weight:100;font-family: 'Montserrat', sans-serif;margin:0px;">
                    {{contactInfo?.description}}</p>
            </div>
            <!-- Details -->

            <div>
                <div *ngIf="contactInfo?.address" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div  style="display:flex;align-items:center;flex-shrink: 0;">
                        <img src="./assets/images/1022/location.png" style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:15px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
                </div>
                <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="display:flex;align-items:center;flex-shrink: 0;">
                        <img src="./assets/images/1022/call.png"  style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:15px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.phone_number_1}}</span>
                </a>
                <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="display:flex;align-items:center;flex-shrink: 0;">
                        <img src="./assets/images/1022/email.png"  style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:15px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
                </a>

                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website" style="display: flex;margin-left:30px;margin-right:30px;">
                    <div style="display:flex;align-items:center;flex-shrink: 0;">
                        <img src="./assets/images/1022/web.png"  style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:15px;display: flex;align-items: center;color:#4d4d4f;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.website}}</span>
                </a>
            </div>

            <!-- Bottom -->
            <div style="margin-top:3rem;">
                <img src="./assets/images/1022/bottom_bg.png" style="width:100%;">
            </div>
            <!-- End of bottom -->

        </div>
    </section>
</body>

</html>