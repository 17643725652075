<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Prompt:wght@300&display=swap" rel="stylesheet">

    <style>
        .profile {
            position: absolute;
            width: 50%;
            left: 50%;
            top: 100px;
            transform: translate(-50%);
            text-align: center;
        }

        .grad-bottom {
            background-color: #1a1d1d;
            min-height: 100px;
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard" style="min-width: 250px; max-width:400px;height:auto;margin:auto;">
            <div style="position:relative;border-bottom:20px solid red;">
                <div style="width:100%;position: relative;">
                    <img src="./assets/images/1016/bg.jpg" style="width:100%;height:auto;object-fit: contain;">
                </div>
                <!-- Profile image -->
                <div style="position:absolute;justify-content: center;top:30px;gap:15px;width:100%;display:grid;align-items: center;margin-top:30px;">
                    <div *ngIf="contactInfo?.company_logo" style="text-align:center">
                        <img [src]="contactInfo?.company_logo"
                            style="width:100px;height:100px;object-fit: contain;margin-bottom:20px;margin: auto;">
                    </div>
                    <div *ngIf="contactInfo?.profile_picture"
                        style="border-radius:100%;padding:5px;border:2px solid white;width:200px;height:200px;object-fit: contain;margin:auto">
                        <img [src]="contactInfo?.profile_picture" style="width:100%;height:100%;border-radius:100%;">
                    </div>
                </div>

            </div>
            <div style="display:flex;justify-content:center;align-items:center;margin-top:-65px;position: relative;">
                <img src="./assets/images/1016/red-strip.png" style="z-index: 1;">
                <div style="position:absolute;z-index:2;text-align: center;">
                    <p
                        style="color:white;margin:0px;font-family:poppins;font-weight:bold;font-size:28px;padding:0px 5px;line-height: 1;">
                        {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                    <p style="color:white;margin:0px;padding:0px 5px;font-family: 'Prompt', sans-serif;line-height:1;font-size:20px;">{{contactInfo?.designation}}
                    </p>
                </div>
            </div>
            <!-- datas -->
            <div
                style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:2.5rem;margin-top:2rem;align-items: center;gap:50px;justify-content: center;">
                <div *ngIf="contactInfo?.phone_number_1">
                    <a [href]="'tel:'+ contactInfo?.phone_number_1">
                        <img src="./assets/images/1016/01.png">
                    </a>
                </div>
                <div *ngIf="contactInfo?.email">
                    <a [href]="'mailto:'+ contactInfo?.email">
                        <img src="./assets/images/1016/02.png">
                    </a>
                </div>
                <div *ngIf="contactInfo?.website">
                    <a [href]="contactInfo?.website">
                        <img src="./assets/images/1016/03.png">
                    </a>
                </div>
            </div>
            <div *ngIf="contactInfo?.whatsapp"
                style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:2rem;align-items: center;gap:20px;justify-content: center;">

                <div style="justify-content: center;display: flex;align-items: center;">
                    <a [href]="'https://wa.me/'+contactInfo?.whatsapp">
                        <img src="./assets/images/1016/04.png">
                        <p
                            style="color:white;font-weight:bold;font-family:poppins;z-index:2;position:absolute;font-size:20px;margin:0px;margin-top:-50px;margin-left:70px;">
                            Contact Me</p>
                    </a>
                </div>

            </div>
            <div class="grad-bottom" style="width:100%;display: flex;gap:10px;">
                <div *ngIf="contactInfo?.address"
                    style="display:flex;gap:10px;align-items: center;padding:20px 30px;padding-right:20px;">
                    <div style="background-color:white;width:35px;height:35px;border-radius:100%;padding:6px;">
                        <img src="./assets/images/1016/location.png" style="width:100%;height:100%;object-fit:contain;">
                    </div>
                    <p style="font-size:14px;font-family:poppins;font-weight: normal;color:white;line-height: 1.2;">
                        {{contactInfo?.address}}</p>
                </div>
                <!-- <div style="margin-top:-20px;margin-right:30px;">
                    <img src="./assets/1/qrcode.jpg" style="width:70px;height:70px;object-fit: contain;">
                </div> -->
            </div>


        </div>

    </section>

</body>

</html>