import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { QRCodeElementType } from 'angularx-qrcode';
import { UserService } from 'app/core/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
declare function downloadQRToDevice(base64ImageData): any;
export interface DialogData {
  shareUrl: string;
  name:string
}

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ShareModalComponent implements OnInit {
  userName
  ngOnInit(): void {
    this.userName = this.data.name
    this.shareUrl = this.data.shareUrl
  }
  constructor(
    public dialogRef: MatDialogRef<ShareModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private userService: UserService,
    private clipboard: Clipboard, private toastr: ToastrService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  elementType = "canvas" as QRCodeElementType
  saveAsImage(parent: any) {
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.")
    }

    if (parentElement) {
      downloadQRToDevice(parentElement)
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      saveAs(blob,this.userName+".png")
      // const url = window.URL.createObjectURL(blob)
      // const link = document.createElement("a")
      // link.href = url
      // // name of the file
      // link.download = "Qrcode"
      // link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }


  shareUrl: string
  copyToClipBoard() {
    this.clipboard.copy(this.shareUrl);
    this.toastr.success("URL copied to clipboard", 'Success')
  }
}
