import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User, UserProfile } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/core/auth/auth.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown, faUserLarge } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls: ['./classy.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    userProfiles: UserProfile[];
    userProfile: UserProfile;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    iconUser = faUserLarge
    iconArrowDown = faAngleDown
    iconChecked = faCheckCircle
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        public _router: Router,
        private cookieService: CookieService,
        private spinner: NgxSpinnerService,
        private _navigationService: NavigationService,
        public _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfirmationService: FuseConfirmationService,
        private toast: ToastrService,
        private authService: AuthService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    iconFacebook = faFacebookF
    iconTwitter = faTwitter
    iconInstagram = faInstagram

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to the user profile
        this._userService.userProfile$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: UserProfile) => {
                this.userProfile = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        // Subscribe to the user profiles
        this._userService.userProfiles$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((profiles: UserProfile[]) => {
                this.userProfiles = profiles;
            });

        this.toggleNavigation('mainNavigation')
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    openUrlInNewTab(url) {
        window.open(url, '_blank')
    }

    onClickLogin() {
        if (this._userService.isLoggedIn()) {
            let config: FuseConfirmationConfig = {
                title: 'Logout',
                message: `Dear ${this.user.name}, Are you sure you want to logout?`,
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation',
                    color: 'warn'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: 'Confirm',
                        color: 'warn'
                    },
                    cancel: {
                        show: true,
                        label: 'Cancel'
                    }
                },
                dismissible: true
            };
            this._fuseConfirmationService.open(config).afterClosed().subscribe(value => {
                if (value == 'confirmed') {
                    this._userService.logOut()
                    this.cookieService.deleteAll("/")
                    this._router.navigate(['/'])
                }
            })
        }
        else {
            // if (this.cookieService.get('token')?.length > 0) {
            let config: FuseConfirmationConfig = {
                title: 'Login',
                message: `You'll be taken to www.printingwarehouses.com to login. Would you like to continue?`,
                icon: {
                    show: true,
                    name: 'heroicons_outline:exclamation',
                    color: 'warn'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: 'Login',
                        color: 'warn'
                    },
                    cancel: {
                        show: true,
                        label: 'Cancel'
                    }
                },
                dismissible: true
            };
            this._fuseConfirmationService.open(config).afterClosed().subscribe(value => {
                if (value == 'confirmed') {
                    window.location.href = environment.production ? 'https://www.printingwarehouses.com/?from=vid' : 'https://www.printingw.xyz/?from=vid'
                    // this.loginWithPWAToken()
                }
                // else {
                //     window.location.href = environment.production ? 'https://www.printingwarehouses.com/' : 'https://www.printingw.xyz/'
                // }
            })
            // } else
            //     window.location.href = environment.production ? 'https://www.printingwarehouses.com/' : 'https://www.printingw.xyz/'
        }
    }

    // loginWithPWAToken() {
    //     this.spinner.show()
    //     this.authService.accessToken = this.cookieService.get('token')
    //     this._userService.getTokenForVID().subscribe(
    //         (res: any) => {
    //             this.spinner.hide()
    //             let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
    //             this.cookieService.set('customToken', res.custom_token, { domain: DOMAIN, sameSite: 'None', expires: new Date(2030, 1, 1), path: '', secure: true });
    //             this._userService.signIn()
    //         }, (err) => {
    //             this.spinner.hide();
    //             this.toast.error(err ? err.error.message : "Something went wrong. Please try again!", 'Sorry')
    //         })
    // }

    switchAccount(account) {
        this.spinner.show()
        this._userService.getUserProfileFromServer(account.profile_id).subscribe(res => {
            localStorage.setItem("current_profile", account.profile_id)
            localStorage.setItem("current_profile_code", account.public_share_code)
            this._userService.userProfile = account
            this.toast.success(`Profile swiched to ${account.profile_name}`, "Success")
            this.spinner.hide()
        }, err => {
            this.spinner.hide()
            this.toast.error(err.error.message ? err.error.message : "Something went wrong, Please try again.", "Sorry")
        })
    }

    showHeaderDivider = false

    @HostListener('window:scroll', [])
    scrollHandler(event) {
        this.showHeaderDivider =  event?.target?.scrollTop > 30        
    }
}
