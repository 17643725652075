<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
</head>

<body>
    <section style="background-color:white;">

        <!-- profile image -->
        <div class="bussinesscard"
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;padding-bottom:3rem;">

            <!--  Profile datas -->
            <div class="row"
                style="display:flex;margin-bottom:0rem;padding-top:5rem;padding-bottom: 2rem;margin-left:20px;margin-right:30px;">
                <div *ngIf="contactInfo?.profile_picture" class="col"
                    style="background-color:#f69383;border-radius:13px;padding-left:10px;padding-right:10px;padding-top:10px;padding-bottom:10px;">
                    <img [src]="contactInfo?.profile_picture" style="width:100px;height:120px;object-fit:cover">
                </div>
                <div class="col" style="text-align:center;display: block;align-self: center;margin-left:30px;">
                    <p
                        style="font-size:28px;margin:0px;color:#4d4d4f;font-weight:600;color:#3c303d;font-family: 'Poppins', sans-serif;text-align: initial;line-height:1.2">
                        {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                    <p style="font-size:20px;margin:0px;color:#606060;font-family: 'Montserrat', sans-serif;text-align: initial;">{{contactInfo?.designation}}</p>
                </div>
            </div>

            <!-- icons -->
            <div class="row" style="display:flex;margin:45px;margin-left:30px;margin-top:0px;margin-bottom:30px;">
                <div class="column" style="text-align:center;padding-right:14px;">
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                    <img src="https://cdn.pwa.ink/assets/Lu5E/01.png" style="width:35px;height:35px;object-fit:contain;">
                    </a>
                </div>
                <div class="column" style="text-align:center;padding-right:14px;">
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                    <img src="https://cdn.pwa.ink/assets/Lu5E/03.png" style="width:35px;height:35px;object-fit:contain;">
                    </a>
                </div>
                <div class="column" style="text-align:center;padding-right:14px;">
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                    <img src="https://cdn.pwa.ink/assets/Lu5E/02.png" style="width:35px;height:35px;object-fit:contain;">
                    </a>
                </div>
            </div>

            <!-- About me -->
            <div style="margin-left:30px;margin-right:33px;margin-bottom: 2rem;;">
                <p *ngIf="contactInfo?.description_title" style="color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:300;font-size:17px;">{{contactInfo?.description_title}}</p>
                <p *ngIf="contactInfo?.description"
                    style="color:#4d4d4f;line-height:1.5;font-size:14px;font-weight:100;font-family: 'Montserrat', sans-serif;">
                    {{contactInfo?.description}}
            </p>
            </div>



            <!-- Details -->

            <div
                style="background-color:#f69383;margin-left:25px;margin-right:25px;margin-bottom:25px;border-radius:30px;padding-top:30px;padding-bottom:30px;">
                <div *ngIf="contactInfo?.address" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="flex-shrink:0;">
                        <img src="https://cdn.pwa.ink/assets/Lu5E/08.png" style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:white;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
                </div>
                <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="flex-shrink:0;">
                        <img src="https://cdn.pwa.ink/assets/Lu5E/07.png" style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:white;font-size:17px;font-family: 'Poppins', sans-serif;"> {{contactInfo?.phone_number_1}}</span>
                </a>
                <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="flex-shrink:0;">
                        <img src="https://cdn.pwa.ink/assets/Lu5E/06.png" style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:white;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
                </a>

                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website" style="display: flex;margin-left:30px;margin-right:30px;">
                    <div style="flex-shrink:0;">
                        <img src="https://cdn.pwa.ink/assets/Lu5E/05.png" style="width:35px;height:35px;object-fit:contain;">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:white;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.website}}</span>
                </a>

            </div>


        </div>


    </section>
</body>

</html>