<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>

    </style>
</head>

<body>
    <div class="bussinesscard"
        style="min-width: 250px; max-width:400px;height:auto;margin:auto;background-color:#e7b24a;">
        <div style="position:relative;background-color:#e7b24a;height:50px;">
        </div>

        <img [src]="contactInfo?.profile_picture" *ngIf="contactInfo?.profile_picture"
            style="margin:auto;display:block;position: relative;border:7px solid #e7b24a;border-radius:100%;object-fit: cover;width:170px;height:170px">
        <!--  Profile datas -->
            <img src="./assets/images/1018/shape.png" style="width:100%;object-fit:contain;margin-top:-100px;">
            <div  style="background-color:#fbe9c4">
                <div style="text-align:center;margin-bottom:30px;margin-top:-10px;">
                    <p
                        style="font-size:28px;margin:0px;color:#18379b;font-weight:550;color:#4d4d4f;font-family: 'Poppins', sans-serif;line-height: 1;">
                        {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                    <p style="font-size:20px;margin:0px;color:#58585b;font-family: 'Montserrat', sans-serif;">{{contactInfo?.designation}}
                    </p>
                </div>

                <!-- profile details -->
                <div class="row" style="margin:2rem;">
                    <div  *ngIf="contactInfo?.phone_number_1" class="column" style="margin:auto;">
                        <div style="margin-bottom:1rem;text-align: center;display:flex;text-align: left;">
                            <a [href]="'tel:'+ contactInfo?.phone_number_1" style="display:flex;justify-content: center;gap:15px;">
                                <img src="./assets/images/1018/call_new.png" style="width:25px;height:25px;object-fit: contain;flex: shrink 0px;">
                            <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;margin-top:0px;">{{contactInfo?.phone_number_1}}</p>
                            </a>
                        </div>
                    </div>
                    <div *ngIf="contactInfo?.email" class="column" style="margin:auto;">
                        <div style="margin-bottom:1rem;text-align: center;text-align: left;">
                            <a [href]="'mailto:'+ contactInfo?.email" style="display:flex;gap:15px;">
                                <img src="./assets/images/1018/email_new.png" style="width:25px;height:25px;object-fit: contain;flex: shrink 0px;">
                            <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;margin-top:0px;">{{contactInfo?.email}}</p>
                            </a>
                        </div>
                    </div>
                    <div *ngIf="contactInfo?.address" class="column" style="margin:auto;">
                        <div style="margin-bottom:1rem;text-align: center;display:flex;gap:15px;text-align: left;">
                                <img src="./assets/images/1018/location_new.png" style="width:25px;height:25px;object-fit: contain;flex: shrink 0px;">
                            <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;margin-top:0px;">{{contactInfo?.address}}</p>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding-bottom:2rem;">
                    <div style="display:flex;gap:10px;justify-content:center;">
                        <div *ngIf="contactInfo?.facebook"
                        style="background-color:#e7b24a;padding:4px;border-radius:40px;width:25px;height:25px;justify-content: center;display: flex;align-items: center;">
                        <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                        <img src="./assets/images/1018/f.png">
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.instagram"
                        style="background-color:#e7b24a;padding:4px;border-radius:40px;width:25px;height:25px;justify-content: center;display: flex;align-items: center;">
                        <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                        <img src="./assets/images/1018/in.png">
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.twitter"
                        style="background-color:#e7b24a;padding:4px;border-radius:40px;width:25px;height:25px;justify-content: center;display: flex;align-items: center;">
                        <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                        <img src="./assets/images/1018/tw.png">
                        </a>
                    </div>
                    </div>
                </div>
            </div>
    </div>




</body>

</html>