<div class="flex flex-col flex-auto min-w-0">

    <!-- Main -->
    <div class="flex-auto  mainDiv"
        [ngStyle]="{'background-color': isShareView ? '#f8f8ff' : 'white', 'padding': isShareView ? '0px' : '75px', 'padding-top': isShareView ? '0px' : '30px'}">

        <div class="Createcard_Div" style="background-color:#f8f8ff;height:auto;"
            [ngStyle]="{'background-color': isShareView ? '#f8f8ff' : '#f8f8ff', 'background-image': isShareView ?  'url(\'./assets/images/bg-logo.png\')' : 'none'}"
            [ngClass]="isShareView ? '' : ' pt-[3rem] pb-[8rem]'">
            <div class="button_row flex pl-[135px] pr-[135px] pb-[50px]" style="justify-content:space-between;"
                *ngIf="!isShareView">
                <button class="flex backButton" (click)="onBackPressed()"
                    style="border:0px; box-shadow: 0 10px 6px 0 rgba(191, 191, 191, 0.16);background-color:white;color:#3c2d52;padding:10px 20px;font-size:12px;font-weight:bold;border-radius:30px;font-family:helvetica;align-items:center;justify-content: center;align-self: center;">
                    <img class="left_Arrow" src="./assets/images/left_Arrow.svg" style="width:13px;height:13px;margin-right:10px;font-family:helveticabold;">
                    BACK</button>
                <button class="flex share_card_btn" (click)="openShareModal()"
                    style="border:0px; box-shadow: 0 10px 6px 0 rgba(191, 191, 191, 0.16);background-color:#2b2172;color:#ffff;padding:12px 25px;font-size:12px;font-weight:bold;border-radius:32px;font-family:helvetica;align-items:center;">
                    <img class="sharebtn_icon" src="./assets/images/share-button.svg"
                        style="width:15px;height:15px;margin-right:13px;align-items: center;">
                    SHARE CARD
                </button>
            </div>
            <div class="div_Content" style="text-align:center;" *ngIf="!isShareView">
                <p class="preview_head" style="font-size:35px;color:#000000;font-weight:normal;font-family:helvetica;margin-bottom:5px;">
                    Hooray!</p>
                <p class="preview_Text" style="font-size:18px;color:#000000;font-weight:normal;font-family:helvetica;">
                    Share your digital business card and inspire your clients and customers digitally.
                </p>
            </div>
            <!-- business card -->
            <div class="businesscard" style="height:auto;margin:auto;padding:3rem 0rem;overflow: auto;">
                <div class="flex p-[50] justify-center items-center m-auto" style="width: fit-content; box-shadow: 2px 5px 15px 0px #17161650;" #previewDiv id="previewDiv">
                    <app-design1 [contactInfo]="contactInfo" *ngIf="designId == 1001"> </app-design1>
                    <app-design2 [contactInfo]="contactInfo" *ngIf="designId == 1002"> </app-design2>
                    <app-design3 [contactInfo]="contactInfo" *ngIf="designId == 1003"> </app-design3>
                    <app-design4 [contactInfo]="contactInfo" *ngIf="designId == 1004"> </app-design4>
                    <app-design5 [contactInfo]="contactInfo" *ngIf="designId == 1005"> </app-design5>
                    <app-design6 [contactInfo]="contactInfo" *ngIf="designId == 1006"> </app-design6>
                    <app-design7 [contactInfo]="contactInfo" *ngIf="designId == 1007"> </app-design7>
                    <app-design8 [contactInfo]="contactInfo" *ngIf="designId == 1008"> </app-design8>
                    <app-design9 [contactInfo]="contactInfo" *ngIf="designId == 1009"> </app-design9>
                    <app-design10 [contactInfo]="contactInfo" *ngIf="designId == 1010"> </app-design10>
                    <app-design11 [contactInfo]="contactInfo" *ngIf="designId == 1011"> </app-design11>
                    <app-design12 [contactInfo]="contactInfo" *ngIf="designId == 1012"> </app-design12>
                    <app-design13 [contactInfo]="contactInfo" *ngIf="designId == 1013"> </app-design13>
                    <app-design14 [contactInfo]="contactInfo" *ngIf="designId == 1014"> </app-design14>
                    <app-design15 [contactInfo]="contactInfo" *ngIf="designId == 1015"> </app-design15>
                    <app-design16 [contactInfo]="contactInfo" *ngIf="designId == 1016"> </app-design16>
                    <app-design17 [contactInfo]="contactInfo" *ngIf="designId == 1017"> </app-design17>
                    <app-design18 [contactInfo]="contactInfo" *ngIf="designId == 1018"> </app-design18>
                    <app-design19 [contactInfo]="contactInfo" *ngIf="designId == 1019"> </app-design19>
                    <app-design20 [contactInfo]="contactInfo" *ngIf="designId == 1020"> </app-design20>
                    <app-design21 [contactInfo]="contactInfo" *ngIf="designId == 1021"> </app-design21>
                    <app-design22 [contactInfo]="contactInfo" *ngIf="designId == 1022"> </app-design22>
                    <app-design23 [contactInfo]="contactInfo" *ngIf="designId == 1023"> </app-design23>
                    <app-design24 [contactInfo]="contactInfo" *ngIf="designId == 1024"> </app-design24>
                    <app-design25 [contactInfo]="contactInfo" *ngIf="designId == 1025"> </app-design25>
                    <app-design26 [contactInfo]="contactInfo" *ngIf="designId == 1026"> </app-design26>
                    <app-design27 [contactInfo]="contactInfo" *ngIf="designId == 1027"> </app-design27>
                    <app-design28 [contactInfo]="contactInfo" *ngIf="designId == 1028"> </app-design28>
                    <app-design29 [contactInfo]="contactInfo" *ngIf="designId == 1029"> </app-design29>
                </div>


            </div>
            <button class="flex" type="button" (click)="downloadContactcard()" *ngIf="isShareView"
                style="border:0px; box-shadow: 0 10px 6px 0 rgba(191, 191, 191, 0.16);background-color:#2b2172;color:#ffff;padding:12px 25px;font-size:13px;;font-weight:bold;border-radius:32px;margin: auto;margin-top:5px;align-items: center;">
                <!-- <img src="./assets/images/share-button.svg"
                    style="width:15px;height:15px;margin-right:13px;align-items: center;"> -->
                SAVE CONTACT
            </button>
            <p class="text-black CopyrightContent mt-[30px] mb-[30px]"  *ngIf="isShareView"
                    style="font-size:13px;font-family: 'Varela Round', sans-serif;opacity:51%;text-align: center;">© 2023 Printing Warehouses of America, Inc. All rights reserved.</p>
            <button class="flex edit_button" type="button" (click)="onBackPressed()" *ngIf="!isShareView" 
                style="border:0px; box-shadow: 0 10px 6px 0 rgba(191, 191, 191, 0.16);background-color:#2b2172;color:#ffff;padding:12px 25px;font-size:13px;;font-weight:bold;border-radius:32px;margin: auto;margin-top:5px;align-items: center;">
                <!-- <img src="./assets/images/share-button.svg"
                    style="width:15px;height:15px;margin-right:13px;align-items: center;"> -->
                Edit Your Details
            </button>

        </div>
    </div>

</div>


<ngx-spinner type="ball-scale-multiple"></ngx-spinner>