<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet">

</head>

<body>
    <section>
        <div class="bussinesscard"
            style="width:375px;height:auto;margin:auto;box-shadow: 2px 5px 15px 0px #17161694;padding-bottom:3rem;background-color:white;">

            <!-- business card -->

            <div style="padding-top:3rem;margin-left:30px;">
                <img [src]="contactInfo?.company_logo" *ngIf="contactInfo?.company_logo"
                    style="width:75px;height:75px;object-fit:contain;">
            </div>
            <hr style="background-color:#ed7f10;border:2px solid #ed7f10;position:relative;top:0px;left:0px;">
            <div *ngIf="contactInfo?.profile_picture"
                style="display:flex;margin:auto;border:2px solid #dbdadb;padding:2px;border-radius:100px;display:flex;justify-content: center;width:fit-content;position: absolute;top:200px;right:170px;">
                <img [src]="contactInfo?.profile_picture"
                    style="width:120px;height:120px;object-fit:cover;border-radius:100%;">
            </div>

            <!-- profile data -->
            <!-- Profie data -->
            <div style="padding-left:30px;padding-right:30px;margin-top:3.5rem;">
                <div style="margin-top:25px;font-family: 'Varela Round', sans-serif;">
                    <p
                        style="font-size:25px;margin:0px;color:#616f6f;font-weight:bolder;font-family: 'Raleway', sans-serif;">
                        {{contactInfo?.first_name}} {{contactInfo?.middle_name}} <span
                            style="color:#ed7f10">{{contactInfo?.last_name}}</span>
                    </p>
                    <p
                        style="font-size:20px;margin:0px;color:#4d4d4f;font-family: 'Montserrat', sans-serif;margin-top:0px;line-height: normal;">
                        {{contactInfo?.designation}}</p>
                    <p *ngIf="contactInfo?.company"
                        style="color:#4d4d4f;font-family: 'Montserrat', sans-serif;;font-size:18px;margin-top:3px;margin-bottom:12px;line-height: normal;">
                        {{contactInfo.company}}</p>
                </div>
            </div>

            <!-- About me -->
            <div  style="padding-left:30px;padding-right:30px;">
                <p *ngIf="contactInfo?.description_title"
                    style="color:#4d4d4f;font-size:20px;font-family: 'Varela Round', sans-serif;font-weight:100;margin-bottom:0px;">
                    {{contactInfo?.description_title}}</p>
                <p *ngIf="contactInfo?.description"
                    style="color:#4d4d4f;line-height:1.5;font-size:14px;font-family: 'Montserrat', sans-serif;margin-top:7px;line-height: normal;">
                    {{contactInfo?.description}}</p>
            </div>

            <!-- profile details -->
            <div class="row" style="margin-top:2rem;margin-left:30px;">
                <div *ngIf="contactInfo?.phone_number_1" class="column" style="display: flex;">
                    <a [href]="'tel:'+ contactInfo?.phone_number_1" style="margin-right:30px;margin-bottom:1rem;display:-webkit-inline-box;gap:15px;">
                        <div style="flex-shrink: 0;">
                            <img src="./assets/images/1021/call.png" style="width:30px;height:30px;object-fit:contain;">
                        </div>
                        <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">
                            {{contactInfo?.phone_number_1}}</p>
                    </a>
                </div>
                <div *ngIf="contactInfo?.email" class="column" style="display: flex;">
                    <a [href]="'mailto:'+ contactInfo?.email" style="margin-right:30px;margin-bottom:1rem;display:-webkit-inline-box;gap:15px;">
                        <div style="flex-shrink: 0;">
                            <img src="./assets/images/1021/email.png" style="width:30px;height:30px;object-fit:contain;">
                        </div>
                        <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">
                            {{contactInfo?.email}}</p>
                    </a>
                </div>
                <div *ngIf="contactInfo?.address" class="column">
                    <div style="margin-right:30px;margin-bottom:1rem;display:-webkit-inline-box;gap:15px;">
                        <div style="flex-shrink: 0;">
                            <img src="./assets/images/1021/location.png" style="width:30px;height:30px;object-fit:contain;">
                        </div>
                        <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">
                            {{contactInfo?.address}}</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
</body>

</html>