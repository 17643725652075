<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet">
    <style>
        .businesscard {

            background-color: #ffffff;
        }

        .grad {
            background-color: #1fa065;
            text-align: center;
            padding: 30px;
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="background-color: white;min-width: 250px; max-width:400px;height:auto;margin:auto;position: relative;">
            <div>
                <img src="./assets/images/1011/bg.jpg"
                    style="width:100%;height:auto;object-fit: contain;position: relative;top:0px;left: 0px;">
                <img src="./assets/images/1011/curve.png"
                    style="position:absolute;top:0px;left:0px;width:100%;object-fit: contain;">
                <!-- company logo -->
                <img [src]="contactInfo?.company_logo" *ngIf="contactInfo?.company_logo"
                    style="position:absolute;top:25px;left:50px;width:100px;height:100px;object-fit: contain;">
                <!--  -->
                <img src="./assets/images/1011/curve023.png"
                    style="width:100%;height:auto;object-fit: cover;position: absolute;top:250px;left:0px;">
            </div>
            <div class="grad">
                <p style="color:#ffffff;font-weight:bold;font-size:35px;margin:0px">{{contactInfo?.first_name}}
                    {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="color:#88fecb;font-weight:bold;font-size:24px;margin:0px;line-height: 0.5;">{{contactInfo?.designation}}</p>
                <p *ngIf="contactInfo?.description"
                    style="color:#ffffff;font-weight:bold;font-size:24px;margin:2px;font-family: 'Cookie', cursive;">
                    {{contactInfo?.description}}</p>
                <hr style="border:1px solid #ffffff;margin:30px 0px;margin-bottom:0px;">

                <div
                    style="display:flex;align-items:center;align-items:center;gap:20px;padding-top:15px;justify-content: center;">
                    <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1">
                        <img src="./assets/images/1011/phone.png" style="width:65px;height:65px;object-fit:contain;">
                    </a>
                    <a *ngIf="contactInfo?.whatsapp" [href]="'https://wa.me/'+contactInfo?.whatsapp">
                        <img src="./assets/images/1011/whatsapp.png" style="width:65px;height:65px;object-fit:contain;">
                    </a>
                    <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email">
                        <img src="./assets/images/1011/email.png" style="width:65px;height:65px;object-fit:contain;">
                    </a>


                </div>
                <div
                    style="display:flex;align-items:center;align-items:center;gap:20px;padding-top:15px;justify-content: center;">
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook">
                        <img src="./assets/images/1011/facebook.png" style="width:65px;height:65px;object-fit:contain;">
                    </a>
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram">
                        <img src="./assets/images/1011/instagram.png"
                            style="width:65px;height:65px;object-fit:contain;">
                    </a>
                </div>
                <hr style="border:1px solid #ffffff;margin:10px 0px;margin-bottom:0px;">
            </div>
        </div>


    </section>

</body>

</html>