export const environment = {
  production: false,
  envName: "Staging",
  firebase: {
    apiKey: "AIzaSyDaipyZmj5R6nLwCScxJ806XbKCXmmR6Is",
    authDomain: "printing-warehouses-staging.firebaseapp.com",
    databaseURL: "https://vid-cards-staging.firebaseio.com",
    projectId: "printing-warehouses-staging",
    storageBucket: "printing-warehouses-staging.appspot.com",
    messagingSenderId: "1059519843701",
    appId: "1:1059519843701:web:edcb323938274107eb573c",
    measurementId: "G-QTV0R3J32X"
  },
  shareUrl:"https://staging.vid.cards/",  
  BASE_URL:"https://api.printingw.xyz/v1",
  subscriptionUrl:"https://www.printingw.xyz/pricing"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDaipyZmj5R6nLwCScxJ806XbKCXmmR6Is",
//   authDomain: "printing-warehouses-staging.firebaseapp.com",
//   databaseURL: "https://printing-warehouses-staging-default-rtdb.firebaseio.com",
//   projectId: "printing-warehouses-staging",
//   storageBucket: "printing-warehouses-staging.appspot.com",
//   messagingSenderId: "1059519843701",
//   appId: "1:1059519843701:web:edcb323938274107eb573c",
//   measurementId: "G-QTV0R3J32X"
// };

