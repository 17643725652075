<html>
    <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    </head>
    <body>
        <div class="bussinesscard"
        style="min-width: 250px; max-width:400px;height:auto;margin:auto;padding-bottom:15px;padding-top:5rem;background-color: #ffffff;">
        <div *ngIf="contactInfo?.profile_picture" style="margin:auto;background-color:#dbdadb;padding:7px;border-radius:100px;display:flex;justify-content: center;width:fit-content">
            <img [src]="contactInfo?.profile_picture" style="width:150px;height:150px;border-radius:100%;">
        </div>
        <div>
            <div style="margin-top:7px;font-family: 'Varela Round', sans-serif;text-align: center;">
                <p style="font-size:25px;margin:0px;color:#5e5e5e;font-weight:600;font-family: 'Poppins', sans-serif;">{{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="font-size:20px;margin:0px;color:#5e5e5e;font-family: 'Poppins', sans-serif;line-height: 1;">{{contactInfo?.designation}}</p>
                
            </div>
        </div>

         <!-- Details -->

         <div style="margin-left:25px;margin-right:25px;border-radius:10px;padding-top:30px;">
            <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="display: flex;margin-bottom:1rem;background-color:#dbdadb;padding:15px 25px;border-radius:40px;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1027/call_new.png" style="width:20px;height:20px;object-fit:contain">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.phone_number_1}}</span>
            </a>

            <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;margin-bottom:1rem;background-color:#dbdadb;padding:15px 25px;border-radius:40px;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1027/email_new.png" style="width:20px;height:20px;object-fit:contain">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
             </a>
            <div *ngIf="contactInfo?.address" style="display: flex;margin-bottom:1rem;background-color:#dbdadb;padding:15px 25px;border-radius:40px;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1027/location_new.png" style="width:20px;height:20px;object-fit:contain">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
            </div>

        </div>

        <div style="text-align: center;margin-left: 35px;margin-right: 35px;">
            <p *ngIf="contactInfo?.description" style="color:#5e5e5e;font-weight:100;font-family: 'Montserrat', sans-serif;font-size:15px;line-height: 1.5;">
                {{contactInfo?.description}}
            </p>
        </div>

         <!-- icons -->
         <div class="row" style="display:flex;margin:45px;justify-content: center;gap:30px;">
            <div *ngIf="contactInfo?.facebook" class="column" style="text-align:center;">
                <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                    <fa-icon [icon]="iconFacebook" class="smmIcon" style="font-size: 20px;color:#606062"></fa-icon>
                </a>
            </div>
            <div  *ngIf="contactInfo?.instagram" class="column" style="text-align:center;">
                <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                    <fa-icon [icon]="iconInstagram" class="smmIcon" style="font-size: 20px;color:#606062"></fa-icon>
                </a>
            </div>
            <div  *ngIf="contactInfo?.twitter" class="column" style="text-align:center;">
                <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                    <fa-icon [icon]="iconTwitter" class="smmIcon" style="font-size: 20px;color:#606062"></fa-icon>
                </a>
            </div>
            <div *ngIf="contactInfo?.pinterest" class="column" style="text-align:center;">
                <a [href]="'https://pinterest.com/'+contactInfo?.pinterest">
                    <fa-icon [icon]="iconPinterest" class="smmIcon" style="font-size: 20px;color:#606062"></fa-icon>
                </a>
            </div>
            <div class="column" style="text-align:center;"  *ngIf="contactInfo?.youtube">
                <a [href]="'https://youtube.com/'+contactInfo?.youtube">
                    <fa-icon [icon]="iconYouTube" class="smmIcon" style="font-size: 20px;color:#606062"></fa-icon>
                </a>
            </div>
        </div>


        </div>
    </body>
</html>