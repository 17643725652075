<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Prompt:wght@200&display=swap" rel="stylesheet">
    <style>
        .businesscard {

            background-color: #ffffff;
            background-image: url(./assets/images/1010/bg.jpg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding-top: 25px;
            padding-bottom: 50px;
        }

        .grad {
            text-align: center;
            padding: 30px 0px;
            position: relative;
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="width:100%;max-width:400px;height:auto;margin:auto;box-shadow: 2px 5px 15px 0px #17161694;position: relative;">
            <img src="./assets/images/1010/lines.png" style="position:absolute;width:100%;object-fit: contain;margin-top:-25px;">
            <div class="grad">
                <!-- company logo -->
                <img [src]="contactInfo?.company_logo" *ngIf="contactInfo?.company_logo"
                    style="width:100px;height:100px;object-fit:contain;margin:auto;margin-bottom:20px;">
                <!--  -->
                <!-- profile image -->
                <div *ngIf="contactInfo?.profile_picture"
                    style="width:150px;height:150px;border-radius:100%;object-fit:contain;border:1px solid white;margin:auto;margin-bottom:15px;padding:3px;">
                    <img [src]="contactInfo?.profile_picture"
                        style="width:100%;height:100%;;border-radius:100%;border:1px solid black;object-fit: cover;">
                </div>
                <!--  -->
                <p style="color:#ffffff;font-weight:bold;font-size:30px;margin:0px">{{contactInfo?.first_name}}
                    {{contactInfo?.middle_name}}<span style="color:#ffffff;font-weight:normal;font-size:28px;margin:0px">  {{contactInfo?.last_name}}</span></p>
                <p style="color:#FFFFFF;font-weight:normal;font-size:20px;margin:0px;font-family: 'Prompt', sans-serif;line-height:0.5;">{{contactInfo?.designation}}</p>

                <div
                    style="display:flex;flex-direction:row-reverse;justify-content:center;align-items:center;gap:20px;justify-content: center;height:300px;padding:0px 20px;">
                    <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1">
                        <img src="./assets/images/1010/01.png"
                            style="width:70px;height:70px;object-fit: contain;margin-top:-150px;">
                    </a>
                    <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email">
                        <img src="./assets/images/1010/02.png"
                            style="width:70px;height:70px;object-fit: contain;margin-top:-85px;">
                    </a>
                    <a *ngIf="contactInfo?.whatsapp" [href]="'https://wa.me/'+contactInfo?.whatsapp">
                        <img src="./assets/images/1010/032.png"
                            style="width:70px;height:70px;object-fit: contain;margin-top:-20px;">
                    </a>
                    <a *ngIf="contactInfo?.website" [href]="contactInfo?.website">
                        <img src="./assets/images/1010/04.png"
                            style="width:70px;height:70px;object-fit: contain;margin-top:50px;">
                    </a>
                </div>
                <!-- <img src="./assets/8/01.jpg" style="width:85px;height:85px;margin-top:35px;margin-bottom:15px;"> -->
                <div style="display:flex;gap:15px;justify-content:center;align-items:center;margin:1.2rem 0rem;">
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram">
                        <img src="./assets/images/1009/instagram.png" style="width:27px;height:27px;object-fit:contain">
                    </a>
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook">
                        <img src="./assets/images/1009/fb.png" style="width:27px;height:27px;object-fit:contain">
                    </a>
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter">
                        <img src="./assets/images/1009/twitter.png" style="width:27px;height:27px;object-fit:contain">
                    </a>
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin">
                        <img src="./assets/images/1009/linkedin.png" style="width:27px;height:27px;object-fit:contain">
                    </a>
                    <a [href]="'https://youtube.com/'+contactInfo?.youtube" *ngIf="contactInfo?.youtube">
                        <img src="./assets/images/1009/youtube.png" style="width:27px;height:27px;object-fit:contain">
                    </a>
                </div>
                <p style="color:#ffffff;font-weight:normal;font-size:15px;margin:auto">{{contactInfo?.address}}</p>
            </div>

        </div>


    </section>

</body>

</html>