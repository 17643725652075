import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { Contact, UserProfile } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { NgxCaptureService } from 'ngx-capture';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShareModalComponent } from './share-modal/share-modal.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  constructor(private _matDialog: MatDialog, private route: ActivatedRoute, private toast: ToastrService,
    private loader: NgxSpinnerService, private _capture: NgxCaptureService,
    private router: Router, private userService: UserService, private _location: Location) { }

  designId = null
  isShareView = false
  userId = null
  ngOnInit(): void {
    this.isShareView = !this.router.url.includes('products')
    if (this.isShareView) {
      this.loader.show()
      this.route.params.subscribe(params => {
        this.userId = params['id'];
      });
      this.userService.getPublicProfile(this.userId).subscribe(profile => {
        this.loader.hide()
        if (profile != null) {
          this.contactInfo = profile
          this.designId = this.contactInfo.designId
        }
        else
          this.router.navigate(['/customization'])
      }, err => {
        this.loader.hide()
        this.toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Error")
        this.router.navigate(['/'])
      })
    }
    else {
      this.loader.show()
      this.route.params.subscribe(params => {
        this.designId = params['id'];
      });
      this.userService.currentProfile$.subscribe(profile => {
        this.loader.hide()
        this.profileInfo = profile
        if (profile != null)
          this.contactInfo = profile.profile_data
        else
          this.router.navigate(['/customization'])
      })
    }
  }
  contactInfo: Contact
  profileInfo: UserProfile

  onBackPressed() {
    this._location.back()
  }

  openShareModal() {
    if (!this.isShareView) {
      this.uploadPreview()
    }
    else {
      this._matDialog.open(ShareModalComponent, {
        data: { shareUrl: `${this.profileInfo.public_share_url}`, name: this.contactInfo.first_name + " " + this.contactInfo.last_name }
      });
    }

  }

  downloadContactcard() {
    this.userService.downloadCard(this.userId)
  }

  @ViewChild('previewDiv', { static: false }) previewDiv: any;
  previewImage
  uploadPreview() {
    this.loader.show()
    const dimensions = this.previewDiv.nativeElement.getBoundingClientRect();
    this._capture?.getImage(this.previewDiv?.nativeElement, false, {
      width: dimensions.width,
      height: dimensions.height,
      useCORS: true,
    }).subscribe({
      next: img => {
        let formData = new FormData()
        formData.append("vid_template_image", this.DataURIToBlob(img))
        this.userService.updateProfile(localStorage.getItem("current_profile"), formData).subscribe({
          next: res => {
            this.loader.hide()
            this._matDialog.open(ShareModalComponent, {
              data: { shareUrl: `${this.profileInfo.public_share_url}`, name: this.contactInfo.first_name + " " + this.contactInfo.last_name }
            });
          },
          error: error => {
            this.loader.hide()
            this._matDialog.open(ShareModalComponent, {
              data: { shareUrl: `${this.profileInfo.public_share_url}`, name: this.contactInfo.first_name + " " + this.contactInfo.last_name }
            });
          }
        });
      }
    });
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

}
