<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">


    <style>
        
        .profile {
            position: absolute;
            width: 50%;
            left: 50%;
            top: 100px;
            transform: translate(-50%);
            text-align: center;
        }

    </style>
</head>

<body>
    <section>
        <div class="businesscard" style="min-width: 250px; max-width:400px;height:auto;margin:auto;">
            <div style="position:relative">
                <div style="width:100%;background-color:#0076fe;height:150px;border-bottom-left-radius: 35px;border-bottom-right-radius: 35px;">
                </div>
                <!-- Profile image -->
                 <div *ngIf="contactInfo?.profile_picture" style="position:absolute;top:40%;left:30%;">
                    <img [src]="contactInfo?.profile_picture" style="width:150px;height:150px;border-radius:100%;object-fit:cover;box-shadow: 2px 5px 15px 0px #17161694;">
                </div>

            </div>
            <!-- Profie data -->
            <div style="text-align:center;margin-top:85px;margin-bottom:30px;">
                <p
                    style="font-size:28px;margin:0px;color:#5e5e5e;font-weight:600;font-family: 'Poppins', sans-serif;line-height: 1;">
                    {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                    <hr style="border:1px solid #4d4d4f;margin:7px 30px;">
                <p style="font-size:20px;margin:0px;color:#5e5e5e;font-family: 'Poppins', sans-serif;font-weight: 300;line-height: 1;">
                    {{contactInfo?.designation}}</p>
            </div>
           
           <!-- datas -->
           <div>
            <div *ngIf="contactInfo?.address" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;align-items: center;">
                <div style="background-color:#0076fe;padding:4px;width:27px;height:27px;justify-content: center;display: flex;align-items: center;flex-shrink:0 ;">
                <img src="./assets/images/1019/location.png">
            </div>
            <span style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">{{contactInfo?.address}}</span>
            </div>
            <a *ngIf="contactInfo?.phone_number_1"  [href]="'tel:'+ contactInfo?.phone_number_1" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;align-items: center;">
                <div style="background-color:#0076fe;padding:4px;width:27px;height:27px;justify-content: center;display: flex;align-items: center;flex-shrink:0 ;">
                <img src="./assets/images/1019/call.png" >
            </div>
            <span style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">{{contactInfo?.phone_number_1}}</span>
               
            </a>
            <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;align-items: center;">
                <div style="background-color:#0076fe;padding:4px;width:27px;height:27px;justify-content: center;display: flex;align-items: center;flex-shrink:0 ;">
                <img src="./assets/images/1019/email.png" >
            </div>
            <span style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">{{contactInfo?.email}}</span>
             </a>
            </div>

            <div style="width:100%;background-color:#0076fe;height:100px;border-top-left-radius:35px;border-top-right-radius:35px;padding-top:35px;margin-top:40px;">
                
                <div class="row" style="display:flex;justify-content: center;align-items:center;">
                <div *ngIf="contactInfo?.facebook" class="column" style="text-align:center;padding:5px;">
                    <div
                        style="background-color:white;padding:3px;border-radius:40px;width:23px;height:23px;justify-content: center;display: flex;align-items: center;">
                        <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                        <img src="./assets/images/1019/f.png" style="width:100%;height:100%;object-fit:contain;">
                        </a>
                    </div>
                </div>
                <div *ngIf="contactInfo?.instagram" class="column" style="text-align:center;padding:5px;">
                    <div
                        style="background-color:white;padding:3px;border-radius:40px;width:23px;height:23px;justify-content: center;display: flex;align-items: center;">
                        <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                        <img src="./assets/images/1019/in.png" style="width:100%;height:100%;object-fit:contain;">
                        </a>
                    </div>
                </div>
                <div *ngIf="contactInfo?.twitter" class="column" style="text-align:center;padding:5px;">
                    <div
                        style="background-color:white;padding:3px;border-radius:40px;width:23px;height:23px;justify-content: center;display: flex;align-items: center;">
                        <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                        <img src="./assets/images/1019/tw.png" style="width:100%;height:100%;object-fit:contain;">
                        </a>
                    </div>
                </div>
                </div>
            </div>

        </div>

    </section>

</body>

</html>