<html>
    <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    </head>
    <body>
        <div class="bussinesscard"
        style="min-width: 250px; max-width:400px;height:auto;margin:auto;background-color: white;">
        <div style="margin:auto;border-radius:100px;position:relative;width:100%;" >
            <img src="./assets/images/1026/curve.png" style="position:relative;top:0px;left:0px;width:100%">
            <div *ngIf="contactInfo?.profile_picture" style="margin:auto;background-color:#dbdadb;padding:5px;border-radius:100px;display:flex;justify-content: center;width:fit-content;position:absolute;top:130px;left:120px;">
                <img [src]="contactInfo?.profile_picture" style="width:130px;height:130px;object-fit:cover;border-radius: 100%;">
            </div>
        </div>
        <div>
            <div style="margin-top:60px;font-family: 'Varela Round', sans-serif;text-align: center;">
                <p style="font-size:25px;margin:0px;color:#5e5e5e;font-weight:600;font-family: 'Poppins', sans-serif;line-height: 1;"> {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="font-size:20px;margin:0px;color:#5e5e5e;font-family: 'Poppins', sans-serif;margin-bottom:20px;"> {{contactInfo?.designation}}</p>
                
            </div>
        </div>

         <!-- Details -->

         <div>
            <div *ngIf="contactInfo?.phone_number_1" style="border-bottom:0px !important;border:1px solid #5e5e5e;"></div>
            <a  *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="margin-left:45px;margin-right:25px;display: flex;margin-bottom:20px;margin-top:20px;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1026/call_new.png" style="width:22px;height:22px;object-fit:contain">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.phone_number_1}}</span>
            </a>
            <div *ngIf="contactInfo?.email" style="border-bottom:0px !important;border:1px solid #5e5e5e;"></div>

            <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="margin-left:45px;margin-right:25px;display: flex;margin-bottom:20px;margin-top:20px;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1026/email_new.png" style="width:22px;height:22px;object-fit:contain">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
            </a>
            <div style="border-bottom:0px !important;border:1px solid #5e5e5e;"></div>
            <div *ngIf="contactInfo?.address" style="margin-left:45px;margin-right:25px;display: flex;margin-bottom:20px;margin-top:20px;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1026/location_new.png" style="width:22px;height:22px;object-fit:contain">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
                
            </div>
            <div *ngIf="contactInfo?.description" style="border-bottom:0px !important;border:1px solid #5e5e5e;"></div>
        </div>

        <div  *ngIf="contactInfo?.description" style="text-align: center;margin-left: 35px;margin-right: 35px;margin-top:30px;margin-bottom:30px;">
            <p style="color:#5e5e5e;font-family: 'Montserrat', sans-serif;font-size:14px;line-height: 1.5;">
                {{contactInfo?.description}}
            </p>
        </div>

         <!-- icons -->
         <div class="row" style="display:flex;padding:30px;padding-bottom:40px;background-color:#6db698;justify-content: center;gap:35px;;">
            <div *ngIf="contactInfo?.facebook" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                    <fa-icon [icon]="iconFacebook" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div  *ngIf="contactInfo?.instagram" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                    <fa-icon [icon]="iconInstagram" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div  *ngIf="contactInfo?.twitter" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                    <fa-icon [icon]="iconTwitter" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div *ngIf="contactInfo?.pinterest" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://pinterest.com/'+contactInfo?.pinterest">
                    <fa-icon [icon]="iconPinterest" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div class="column" style="text-align:center;display: flex;display: flex;justify-content: center;"  *ngIf="contactInfo?.youtube">
                <a [href]="'https://youtube.com/'+contactInfo?.youtube">
                    <fa-icon [icon]="iconYouTube" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
        </div>


        </div>
    </body>
</html>