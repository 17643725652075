<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close>CANCEL</button> -->
  <button [mat-dialog-close]="true" class="close-button" style="margin-right:5px;margin-top:-20px;">
    <img src="./assets/images/close-button.svg" style="width:15px;height:15px;">
  </button>
</mat-dialog-actions>
<!-- <h2 mat-dialog-title>Share E-Card</h2> -->
<mat-dialog-content class="mat-typography" style="border-radius:0px !important;">
  <div class="" style="text-align:center;">
    <p class="modal_heading"
      style="font-size:30px;color:#000000;font-weight:normal;font-family:helvetica;margin-bottom:17px;margin-top: 15px;line-height: normal;">
      Share your Virtual Biz Card!</p>
    <p class="modalText"
      style="max-width: 550px;font-size:18px;color:#000000;font-weight:normal;font-family:helvetica;margin-bottom:20px;">
      Share your Virtual Biz Card using a QR code or send it through email, text, social media, and more.</p>
    <!-- <img src="./assets/images/qrcode.png" style="width:150px;height:150px;margin:auto;"> -->

    <qrcode [qrdata]="shareUrl" [width]="200" #qrCode
      style="width:150px;height:150px;margin:auto;text-align: -webkit-center;"></qrcode>
  </div>
  <div class="flex gap-3 flex-col md:flex-row p-3" style="justify-content:center;align-items:center;margin-top:10px;">
    <share-buttons [theme]="'circles-dark'" [include]="['facebook','twitter','linkedin','whatsapp','email']" [show]="5"
      [url]="shareUrl" [autoSetMeta]="false" [title]="'Virtual ID Card'" [url]="shareUrl"></share-buttons>
    <button class="flex copylink_button" (click)="copyToClipBoard()"
      style="border:2px solid #4a277e;background-color:white;color:#4a277e;padding:8px 45px;font-size:13px;;font-weight:bold;border-radius:32px;">
      COPY LINK
    </button>

  </div>


</mat-dialog-content>
<mat-dialog-actions>
  <button class="flex modal_Button" (click)="saveAsImage(qrCode)"
    style="margin:auto;margin-top:25px;border:0px; box-shadow: 0 10px 6px 0 rgba(191, 191, 191, 0.16);background-color:#2b2172;color:#ffff;padding:10px 30px;font-size:12px;font-weight:bold;border-radius:32px;">
    DOWNLOAD QR CODE
  </button>
</mat-dialog-actions>