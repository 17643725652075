import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { EmailSignature, EmailSignatureResponse } from './email-signature.model';
import { environment } from 'environments/environment';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class EmailSignatureService {

    private _emailSignatures: BehaviorSubject<EmailSignatureResponse | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for emailSignatures
     */
    get emailSignatures$(): Observable<EmailSignatureResponse> {
        return this._emailSignatures.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get emailSignature list
     */
    getEmailSignaturesInitial(): Observable<EmailSignatureResponse> {
        return this.getEmailSignatures(null, null, null, null, null);
    }

    /**
     * Get emailSignature list with search and sort
     */
    getEmailSignatures(page: number = 1, batch_size: number = 12, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<EmailSignatureResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<EmailSignatureResponse>(`${environment.BASE_URL}/email_signature/`, {
            params: {
                page: page ? page : 1,
                sort: sort ? sort : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 12
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: response.current_page,
                    total_count: response.total_count,
                    total_page_count: response.total_page_count
                };
                this._emailSignatures.next(response);
            })
        );
    }

    getSignatureDetails(signatureId): Observable<any> {
        return this._httpClient.get(`${environment.BASE_URL}/email_signature/${signatureId}/autofill/${localStorage.getItem("current_profile_code")}/`, { responseType: 'text' })
    }

}
