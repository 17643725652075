<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>
        .businesscard {

            background-color: #4D3BAF;
        }

        .grad {
            background-color: white;
            padding: 30px 30px 7px 30px;
            height:150px;
            display:flex;
            gap:7px;
            align-items: end;
            justify-content: center;
            clip-path:polygon(0 0%, 100% 30%, 100% 100%, 0 100%);
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;position: relative;">

            <div style="padding-top:70px;">
                <img [src]="contactInfo?.company_logo" *ngIf="contactInfo?.company_logo"
                    style="width:100px;height:auto;object-fit:contain;display:block;margin:auto;margin-bottom:1rem;">
                <div
                    style="border:2px solid white;width:150px;height:150px;border-radius:100%;display: block;margin: auto;background-color:white;">
                    <img [src]="contactInfo?.profile_picture" *ngIf="contactInfo?.profile_picture" style="width:100%;height:100%;object-fit: cover;border-radius:100%;">
                </div>
                <p
                    style="font-size:28px;color:white;font-family:poppins;margin:0px;text-align: center;padding:15px 5px;padding-bottom:0px;line-height: 1;">
                    {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p
                    style="font-size:20px;color:white;font-family:poppins;margin:0px;text-align: center;padding:5px 5px;line-height: 0.7;">
                    {{contactInfo?.designation}}</p>
                <div class="grad" style="margin-top:30px;">
                    <div *ngIf="contactInfo?.phone_number_1" style="display:block;text-align:center">
                        <a [href]="'tel:'+ contactInfo?.phone_number_1">
                        <img src="./assets/images/1014/01.png" style="width:50px;height:50px;object-fit:contain;margin:auto">
                        <p style="font-size:12px;color:#4D3BAF;font-family:poppins;margin:0px;text-align: center;line-height: 3;">CALL</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.whatsapp" style="display:block;text-align:center">
                        <a [href]="'https://wa.me/'+contactInfo?.whatsapp">
                        <img src="./assets/images/1014/02.png" style="width:50px;height:50px;object-fit:contain;margin:auto">
                        <p style="font-size:12px;color:#4D3BAF;font-family:poppins;margin:0px;text-align: center;line-height: 3;">WHATSAPP</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.email" style="display:block;text-align:center">
                        <a [href]="'mailto:'+ contactInfo?.email">
                        <img src="./assets/images/1014/03.png" style="width:50px;height:50px;object-fit:contain;margin:auto">
                        <p style="font-size:12px;color:#4D3BAF;font-family:poppins;margin:0px;text-align: center;line-height: 3;">E-MAIL</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.website" style="display:block;text-align:center">
                        <a [href]="contactInfo?.website">
                        <img src="./assets/images/1014/04.png" style="width:50px;height:50px;object-fit:contain;margin:auto">
                        <p style="font-size:12px;color:#4D3BAF;font-family:poppins;margin:0px;text-align: center;line-height: 3;">WEB</p>
                        </a>
                    </div>
                </div>
                <!-- <p style="color:white;font-family:poppins;font-size:20px;text-align: center;margin-top:20px;">FOLLOW ME</p> -->
                <div style="display:flex;gap:14px;justify-content:center;align-items:center;padding:2rem 0rem;">
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram">
                    <img src="./assets/images/1014/instagram.png" style="width:20px;height:2-px;object-fit:contain;margin:auto">
                    </a>
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook">
                    <img src="./assets/images/1014/fb.png" style="width:20px;height:20px;object-fit:contain;margin:auto">
                    </a>
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter">
                    <img src="./assets/images/1014/twitter.png" style="width:20px;height:20px;object-fit:contain;margin:auto">
                    </a>
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin">
                    <img src="./assets/images/1014/linkedin.png" style="width:20px;height:20px;object-fit:contain;margin:auto">
                    </a>
                    <a [href]="'https://youtube.com/'+contactInfo?.youtube" *ngIf="contactInfo?.youtube">
                    <img src="./assets/images/1014/youtube.png" style="width:20px;height:20px;object-fit:contain;margin:auto">
                    </a>

                </div>

            </div>
        </div>


    </section>

</body>

</html>