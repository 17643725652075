import { Component, Input, OnInit } from '@angular/core';
import { faFacebookF, faInstagram, faLinkedinIn, faPinterestP, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faGlobe, faLocationDot, faPhone, faTty } from '@fortawesome/free-solid-svg-icons';
import { Contact } from 'app/core/user/user.types';

@Component({
  selector: 'app-design2',
  templateUrl: './design2.component.html',
  styleUrls: ['./design2.component.css']
})
export class Design2Component implements OnInit {
  @Input() contactInfo?: Contact;
  constructor() { }

  iconFacebook = faFacebookF
  iconTwitter = faTwitter
  iconInstagram = faInstagram
  iconPinterest = faPinterestP
  iconYouTube = faYoutube
  iconEmail = faEnvelope
  iconPhone = faPhone
  iconLocation = faLocationDot
  iconWeb = faGlobe
  iconTelephone = faTty
  iconWhatsapp=faWhatsapp
  iconLinkedIn = faLinkedinIn

  ngOnInit(): void {
  }

}
