<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>

    </style>
</head>

<body>
    <div class="bussinesscard"
        style="min-width: 250px; max-width:400px;height:auto;margin:auto;background-color: #ffffff;">
        <div style="position:relative;">
            <div  *ngIf="contactInfo?.company_logo" style="display: flex;justify-content: center;">
                <img [src]="contactInfo?.company_logo"
                    style="margin:auto;display:block;position: absolute;padding-top:30px;width:70px;height:auto;object-fit: contain;">
            </div>
            <div style="background-color:#dc1c2e;width:100%;height:230px;">

            </div>
            <img *ngIf="contactInfo?.profile_picture" [src]="contactInfo?.profile_picture"
                style="margin-top:-90px !important;margin:auto;display:block;position: relative;width:120px;height:120px;object-fit: cover;border-radius:100%;border:2px solid #dbdadb;">
            <!--  Profile datas -->
            <div style="text-align:center;margin-top:6px;margin-bottom:25px ;">
                <p
                    style="font-size:25px;margin:0px;color:#dc1c2e;font-weight:550;color:#dc1c2e;font-family: 'Poppins', sans-serif;">
                    {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="font-size:20px;margin:0px;color:#535353;font-family: 'Poppins', sans-serif;line-height: 0.5;">{{contactInfo?.designation}}</p>
            </div>
            <div *ngIf="contactInfo?.description"
                style="text-align:center;font-size:15px;font-weight:100;font-family: 'Montserrat', sans-serif;margin-left:50px;margin-right: 50px;color:#4d4d4f;margin-bottom:25px;line-height: 1.5;">
                {{contactInfo?.description}}
            </div>

            <!-- profile details -->
            <div class="row" style="margin-left:50px;margin-right: 50px;margin-bottom: 5px;gap:20px;">
                <div style="display: flex;">
                    <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="margin-bottom:1rem;display: flex;align-items: center;gap:20px;">
                        <div
                            style="background-color:#18379b;padding:5px;border-radius:40px;display: flex;width:30px;height:30px;align-items: center;flex-shrink:0;">
                            <img src="./assets/images/1029/call.png" style="width:25px;height:25px;object-fit: contain;">
                        </div>
                        <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;text-align: center;">{{contactInfo?.phone_number_1}}</p>
                    </a>
                </div>
                <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;">
                    <div style="margin-bottom:1rem;display: flex;align-items: center;gap:20px;">
                        <div
                            style="background-color:#18379b;padding:5px;border-radius:40px;display: flex;width:30px;height:30px;align-items: center;flex-shrink:0;">
                            <img src="./assets/images/1029/email.png" style="width:25px;height:25px;object-fit: contain;">
                        </div>
                        <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</p>
                    </div>
                </a>
                <div *ngIf="contactInfo?.address" style="display: flex;">
                    <div style="margin-bottom:1rem;display: flex;align-items: center;gap:20px;">
                        <div
                            style="background-color:#18379b;padding:5px;border-radius:40px;display: flex;width:30px;height:30px;align-items: center;flex-shrink:0;">
                            <img src="./assets/images/1029/location.png" style="width:25px;height:25px;object-fit: contain;">
                        </div>
                        <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</p>
                    </div>
                </div>

            </div>
            <div style="background-color:#2d407c;height:20px;">
            </div>

        </div>

</div>


</body>

</html>