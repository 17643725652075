<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>
        body {
            font-family: 'Poppins', sans-serif;
        }
    </style>
</head>

<body style="background-color: transparent !important;">
    <section>
        <div class="bussinesscard"
            style="width: 100%;max-width: 400px;;height:auto;margin:auto;background-color: white;">

            <div class="flex flex-row items-center"
                style="background-color:#dc1c2e;width:100%;padding:40px 30px;">

                <img [src]="contactInfo?.profile_picture" *ngIf="contactInfo?.profile_picture"
                    style="width:100px;height:100px;border:3px solid rgba(221, 227, 240);border-radius:50%;padding:3px;object-fit: cover;">
                <div class="flex flex-col justify-center pl-3">
                    <div
                        style="color:white;;font-weight:bold;font-size:25px;line-height:1.2;font-family: 'Poppins', sans-serif;overflow-wrap: anywhere;">
                        {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}
                    </div>
                    <div
                        style="color:white;text-align:initial;font-size:20px;font-weight:300;font-family: 'Poppins', sans-serif;">
                        {{contactInfo?.designation}}</div>
                </div>

            </div>
            <!-- About me -->
            <div style="padding-left:30px;padding-right:20px;margin-top:2rem;">
                <p style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;"
                    *ngIf="contactInfo?.description_title">{{contactInfo?.description_title}}
                </p>
                <p style="color:#4d4d4f;line-height:1.5;font-size:13px;font-weight:100;font-family: 'Montserrat', sans-serif;"
                    *ngIf="contactInfo?.description">
                    {{contactInfo?.description}}</p>
            </div>
            <!-- company logo -->
            <div style="margin-left:30px;object-fit:contain;margin-bottom:1.5rem;margin-top:1.5rem;">
                <img [src]="contactInfo?.company_logo"
                    style="max-height:80px;max-width:180px;object-fit: contain;width: auto;"
                    *ngIf="contactInfo?.company_logo">
                <p style="font-size:17px;margin-top:7px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight: 400;"
                    *ngIf="contactInfo?.company">
                    {{contactInfo.company}}</p>
            </div>

            <!-- datas -->
            <div style="margin-bottom:3rem;">
                <div *ngIf="contactInfo?.address"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;margin-top:2rem;">
                    <fa-icon [icon]="iconLocation"
                        style="padding:10px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">
                        {{contactInfo?.address}}
                    </span>
                </div>
                <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconPhone"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.phone_number_1}}</span>
                </a>
                <a style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                    *ngIf="contactInfo?.phone_number_2" [href]="'tel:'+ contactInfo?.phone_number_2">
                    <fa-icon [icon]="iconTelephone"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:300">
                        {{contactInfo?.phone_number_2}}</span>

                </a>
                <a *ngIf="contactInfo?.email" [href]="'tel:'+ contactInfo?.email"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconEmail"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.email}}</span>
                </a>
                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconWeb"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.website}}</span>
                </a>

            </div>
            <!-- footer -->
            <div style="width:100%;background-color:#dc1c2e;max-height:125px;padding:30px 0px;">
                <p *ngIf="contactInfo?.twitter"
                    style="align-items: center;color:white;font-size:13px;font-family: 'Poppins', sans-serif;text-align: center;margin:0px;line-height: 2;">
                    @{{contactInfo?.twitter}}</p>
                <div class="row" style="display:flex;justify-content: center;align-items:center;">
                    <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.facebook">
                        <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                            <fa-icon [icon]="iconFacebook" class="smmIcon"></fa-icon>
                        </a>
                    </div>
                    <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.instagram">
                        <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                            <fa-icon [icon]="iconInstagram" class="smmIcon"></fa-icon>
                        </a>
                    </div>
                    <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.twitter">
                        <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                            <fa-icon [icon]="iconTwitter" class="smmIcon"></fa-icon>
                        </a>
                    </div>
                    <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.pinterest">
                        <a [href]="'https://pinterest.com/'+contactInfo?.pinterest">
                            <fa-icon [icon]="iconPinterest" class="smmIcon"></fa-icon>
                        </a>
                    </div>
                    <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.youtube">
                        <a [href]="'https://youtube.com/'+contactInfo?.youtube">
                            <fa-icon [icon]="iconYouTube" class="smmIcon"></fa-icon>
                        </a>
                    </div>
                    <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.linkedin">
                        <a [href]="'https://linkedin.com/'+contactInfo?.linkedin">
                            <fa-icon [icon]="iconLinkedIn" class="smmIcon"></fa-icon>
                        </a>
                    </div>
                    <div class="column" style="text-align:center;padding:5px;" *ngIf="contactInfo?.whatsapp">
                        <a [href]="'https://wa.me/'+contactInfo?.whatsapp">
                            <fa-icon [icon]="iconWhatsapp" class="smmIcon"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>

        </div>

    </section>
</body>

</html>