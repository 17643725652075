<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
</head>

<body>
    <section>
        <div class="bussinesscard"
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;background-color: white;">

            <!-- business card -->

            <div style="width:100%;background-color:#581f77;background-image: url('./assets/images/1025/top-bg.png');background-size: cover;height:320px;">
                <img [src]="contactInfo?.company_logo" style="margin-left:20px;margin-right:30px;padding-top:20px;width:70px;height:70px;object-fit: contain;"  *ngIf="contactInfo?.company_logo">
                <div  *ngIf="contactInfo?.profile_picture" style="margin:auto;background-color:#dbdadb;padding:4px;border-radius:100px;display:flex;justify-content: center;width:150px;height:150px;">
                    <img [src]="contactInfo?.profile_picture" style="width:100%;height:100%;object-fit:cover;border-radius:100%;">
                </div>
                <div style="margin-top:10px;font-family: 'Varela Round', sans-serif;text-align: center;margin-bottom:20px;">
                    <p style="font-size:25px;margin:0px;color:whitesmoke;font-weight:600;font-family: 'Poppins', sans-serif;line-height: 1;">{{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                    <p style="font-size:20px;margin:0px;color:white;font-family: 'Poppins', sans-serif;">{{contactInfo?.designation}}</p>
                    
                </div>


            </div>
             <!-- Details -->

         <div style="margin-left:35px;margin-right:25px;border-radius:10px;padding-top:3rem;margin-bottom:4rem;">
            <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="display: flex;margin-bottom:1.5rem;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1025/phone_new.png" style="width:25px;height:25px;object-fit: contain;">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.phone_number_1}}</span>
             </a>

            <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;margin-bottom:1.5rem;">
                <div style="display:flex;justify-content:center;align-items:center;flex-shrink:0;">
                <img src="./assets/images/1025/email_new.png" style="width:25px;height:25px;object-fit: contain;">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
            </a>
            <div *ngIf="contactInfo?.address" style="display: flex;margin-bottom:1.5rem;">
                <div style="display:flex;justify-content:center;align-items:center;width:30px;flex-shrink:0;">
                <img src="./assets/images/1025/location_new.png" style="width:25px;height:25px;object-fit: contain;">
            </div>
                <span style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:15px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
            </div>

        </div>

        <div style="background-color:#581f77;width:100%;position: relative;">
            <img src="./assets/images/1025/bottom.png" style="margin-top:-25px;position: absolute;">

             <!-- icons -->
         <div class="row" style="display:flex;padding:30px;padding-bottom:40px;gap:35px;justify-content: center;">
            <div *ngIf="contactInfo?.facebook" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                    <fa-icon [icon]="iconFacebook" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div  *ngIf="contactInfo?.instagram" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                    <fa-icon [icon]="iconInstagram" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div  *ngIf="contactInfo?.twitter" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                    <fa-icon [icon]="iconTwitter" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div *ngIf="contactInfo?.pinterest" class="column" style="text-align:center;display: flex;justify-content: center;">
                <a [href]="'https://pinterest.com/'+contactInfo?.pinterest">
                    <fa-icon [icon]="iconPinterest" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
            <div class="column" style="text-align:center;display: flex;display: flex;justify-content: center;"  *ngIf="contactInfo?.youtube">
                <a [href]="'https://youtube.com/'+contactInfo?.youtube">
                    <fa-icon [icon]="iconYouTube" class="smmIcon" style="font-size: 20px;color:#ffffff"></fa-icon>
                </a>
            </div>
        </div>
        </div>
    </div>
    </section>
</body>

</html>