import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from './core/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _userService: UserService, private authService: AuthService, private cookieService: CookieService
    ) {

        // this.cookieService.set('token','')

        if (this.cookieService.get('token')?.length > 0) {
            this.authService.accessToken = this.cookieService.get('token')
            // this.cookieService.delete('token', '/',)
            // let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
            // this.cookieService.set('token', '', { domain: DOMAIN, sameSite: 'None', expires: new Date(2020, 1, 1), path: '', secure: true })
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (this._userService.isLoggedIn())
            return forkJoin({
                user: this._userService.get(),
                userProfiles: this._userService.getUserProfiles().pipe(
                    catchError(err => {
                        return of([]);
                    })
                )
            })
        else
            return of(null)
    }
}
