import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PreviewComponent } from './modules/admin/preview/preview.component';
import { CustomizationComponent } from './modules/admin/customization/customization.component';
import { InitialDataResolver } from './app.resolvers';
import { ProfileDataResolver } from './modules/admin/customization/customization.resolvers';
import { EmailSignatureDataResolver } from './modules/admin/email-signatures/email-signature.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/home'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed in user to the '/home'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Admin routes
    {
        path: '',
        resolve: { user: InitialDataResolver },
        component: LayoutComponent,
        children: [
            {
                path: 'home',
                resolve: { signatures: EmailSignatureDataResolver }, loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)
            },
            { path: 'products', loadChildren: () => import('app/modules/admin/products/products.module').then(m => m.ProductsModule) },
            {
                path: 'email-signatures',
                resolve: { signatures: EmailSignatureDataResolver }, loadChildren: () => import('app/modules/admin/email-signatures/email-signatures.module').then(m => m.EmailSignaturesModule)
            },
            // {
            //     path: 'customization',
            //     canActivate: [AuthGuard],
            //     canActivateChild: [AuthGuard], loadChildren: () => import('app/modules/admin/customization/customization.module').then(m => m.CustomizationModule)
            // },
            { path: 'products/:id/', loadChildren: () => import('app/modules/admin/preview/preview.module').then(m => m.PreviewModule) },
            // { path: 'contactus', loadChildren: () => import('app/modules/admin/contactus/contactus.module').then(m => m.ContactusModule) },
            { path: 'pricing', loadChildren: () => import('app/modules/admin/pricing/pricing.module').then(m => m.PricingModule) },
            {
                path: 'products/:id',
                resolve: { user: ProfileDataResolver },
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard], loadChildren: () => import('app/modules/admin/customization/customization.module').then(m => m.CustomizationModule)
            },
            {
                path: 'profiles',
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard], loadChildren: () => import('app/modules/admin/profile-management/profile-management.module').then(m => m.ProfileManagementModule)
            },
        ]
    },



    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: ':id', component: PreviewComponent },
            { path: 'landing', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },
];
