<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">


    <style>
        .grad {
            background-color: #343434;
            min-height: 300px;
            clip-path: polygon(0 0%, 100% 0%, 0% 100%, 0 50%);
        }

        .profile {
            position: absolute;
            width: 50%;
            left: 50%;
            top: 100px;
            transform: translate(-50%);
            text-align: center;
        }

        .grad-bottom {
            background-color: #dfcfaa;
            min-height: 50px;
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;background-color:black;">
            <div style="position:relative">
                <div *ngIf="contactInfo?.company_logo" class="grad" style="width:100%;position: relative;">
                    <img [src]="contactInfo?.company_logo" style="padding:30px 7px 20px 30px;width:100px;height:100px;object-fit:contain;">
                </div>
                <!-- Profile image -->

                <div *ngIf="contactInfo?.profile_picture" style="position:absolute;top:20%;right:15%;">
                    <img [src]="contactInfo?.profile_picture"
                        style="border-radius:100%;border:5px solid #dfcfaa;width:150px;height:150px;object-fit: cover;">
                </div>

            </div>
            <!-- Profie data -->
            <div style="text-align:center;margin-top:-60px;margin-bottom:30px;">
                <p
                    style="font-size:28px;margin:0px;color:#dccca2;font-weight:600;font-family: 'Poppins', sans-serif;line-height: 1;text-align: right;margin-right:30px;">
                    {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p
                    style="font-size:20px;margin:0px;color:#dccca2;font-family: 'Poppins', sans-serif;font-weight: 500;text-align: right;margin-right:30px;">
                    {{contactInfo?.designation}}</p>
                <p *ngIf="contactInfo?.company"
                    style="font-size:18px;margin:0px;color:#aea07b;font-family: 'Poppins', sans-serif;font-weight: 500;text-align: right;margin-right:30px;">
                    {{contactInfo.company}}</p>
            </div>


            <!-- datas -->
            <div
                style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;align-items: center;gap:15px;justify-content: center;">
                <div *ngIf="contactInfo?.phone_number_1"
                    style="justify-content: center;display: flex;align-items: center;">
                    <a [href]="'tel:'+ contactInfo?.phone_number_1">
                    <img src="./assets/images/1017/ph.png" style="width:60px;height:60px;object-fit:contain">
                    </a>
                </div>
                <div *ngIf="contactInfo?.email"
                    style="justify-content: center;display: flex;align-items: center;">
                    <a [href]="'mailto:'+ contactInfo?.email">
                    <img src="./assets/images/1017/em.png" style="width:65px;height:60px;object-fit:contain">
                    </a>
                </div>
                <div *ngIf="contactInfo?.whatsapp"
                    style="justify-content: center;display: flex;align-items: center;">
                    <a [href]="'https://wa.me/'+contactInfo?.whatsapp">
                    <img src="./assets/images/1017/w.png" style="width:60px;height:60px;object-fit:contain">
                    </a>
                </div>
                <!-- <div
                    style="justify-content: center;display: flex;align-items: center;">
                    <img src="./assets/images/1017/m.png" style="width:100%;height:100%;object-fit:contain">
                </div> -->
                <div *ngIf="contactInfo?.facebook"
                style="justify-content: center;display: flex;align-items: center;">
                <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                <img src="./assets/images/1017/fb.png" style="width:60px;height:60px;object-fit:contain">
                </a>
            </div>

            </div>
            <div
                style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1.5rem;align-items: center;gap:15px;justify-content: center;">
               
                <div *ngIf="contactInfo?.instagram"
                    style="justify-content: center;display: flex;align-items: center;">
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                    <img src="./assets/images/1017/instagram.png" style="width:60px;height:60px;object-fit:contain">
                    </a>
                </div>
                <div *ngIf="contactInfo?.twitter"
                    style="justify-content: center;display: flex;align-items: center;">
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                    <img src="./assets/images/1017/t.png" style="width:60px;height:60px;object-fit:contain">
                    </a>
                </div>
                <div
                    style="justify-content: center;display: flex;align-items: center;" *ngIf="contactInfo?.linkedin">
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin">
                    <img src="./assets/images/1017/li.png" style="width:60px;height:60px;object-fit:contain">
                    </a>
                </div>

            </div>
            <hr style="border:1px solid #e2d3a9;width:300px;margin:0px 30px;">
            <p *ngIf="contactInfo?.description" style="font-size:15px;text-align:center;color:#e2d3a9;margin:2rem;font-family:poppins">{{contactInfo?.description}}</p>
            <div class="grad-bottom" style="width:100%;display: flex;justify-content: center;align-items: center;">
                <!-- icons -->


            </div>


        </div>

    </section>

</body>

</html>