<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>
         body{
            font-family: 'Poppins', sans-serif;
        }

    </style>
</head>

<body>
    <section>
        <div class="bussinesscard"
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;padding-bottom:15px;background-color: #ffffff;">
            <div style="position:relative;margin-bottom:-50px;">
                <img [src]="contactInfo?.company_logo"  *ngIf="contactInfo?.company_logo" style="margin-left:20px;margin-right:30px;padding-top:25px;position: absolute;width:100px;height:100px;object-fit: contain;">
                <img src="./assets/images/1028/bg.jpg" style="width:100%;">
            </div>
            <div style="background-color:#dc1c2e;width:100%;padding-bottom:20px;padding-top:20px;display: flex;margin-top:-35px;">

                <img *ngIf="contactInfo?.profile_picture" [src]="contactInfo?.profile_picture"
                    style="width:130px;height:130px;border:3px solid rgba(221, 227, 240);border-radius:50%;padding:3px;display: block;margin-left:30px;position: relative;">
                    <div style="text-align:center;padding-top:60px;">
                    <span
                    style="text-align:center;color:white;;font-weight:bold;font-size:25px;align-self: center;line-height:1.2;margin-left:15px;margin-right:12px;font-family: 'Poppins', sans-serif;">{{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</span>
                    <p style="color:white;margin-left:15px !important;margin:0px;margin-right:12px;text-align:initial;font-size:20px;font-weight:300;font-family: 'Poppins', sans-serif;">{{contactInfo?.designation}}</p>
                </div>

            </div>
            <!-- About me -->
            <div style="padding-left:30px;padding-right:20px;margin-top:2rem;">
                <p *ngIf="contactInfo?.description_title" style="color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.description_title}}</p>
                <p  *ngIf="contactInfo?.description" style="color:#4d4d4f;line-height:1.5;font-size:13px;font-weight:100;font-family: 'Montserrat', sans-serif;">{{contactInfo?.description}}</p>
            </div>

             <!-- datas -->
             <div>
                <div *ngIf="contactInfo?.address" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;margin-top:2rem;">
                    <div style="background-color:#dc1c2e;padding:5px;border-radius:40px;display: flex;justify-content: center;width:30px;height:30px;align-items: center;flex-shrink:0;">
                    <img src="./assets/images/1028/location.png" style="width:100%;height:100%;object-fit:contain;">
                </div>
                    <span style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.address}}</span>
                </div>
                <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="background-color:#dc1c2e;padding:5px;border-radius:40px;display: flex;justify-content: center;width:30px;height:30px;align-items: center;flex-shrink:0;">
                    <img src="./assets/images/1028/call.png" style="width:100%;height:100%;object-fit:contain;">
                </div>
                    <span style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.phone_number_1}}</span>
                </a>
                <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="background-color:#dc1c2e;padding:5px;border-radius:40px;display: flex;justify-content: center;width:30px;height:30px;align-items: center;flex-shrink:0;">
                    <img src="./assets/images/1028/email.png" style="width:100%;height:100%;object-fit:contain;">
                </div>
                    <span style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.email}}</span>
                </a>
                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website" style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <div style="background-color:#dc1c2e;padding:5px;border-radius:40px;display: flex;justify-content: center;width:30px;height:30px;align-items: center;flex-shrink:0;">
                    <img src="./assets/images/1028/web.png" style="width:100%;height:100%;object-fit:contain;">
                </div>
                    <span style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:300;">{{contactInfo?.website}}</span>
                </a>
                
                </div>

        </div>

    </section>
</body>

</html>