import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EmailSignatureService } from 'app/core/email-signature/email-signature.service';

@Injectable({
    providedIn: 'root'
})
export class EmailSignatureDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _signatureService: EmailSignatureService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this._signatureService.getEmailSignaturesInitial()
    }
}
