<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>
        .businesscard {

            background-color: #ffffff;
        }

        .grad {
            background-color: #bb31dd;
            margin-top: -80px;
            clip-path: polygon(0 0%, 100% 20%, 100% 100%, 0 100%);
            padding:50px 0px;
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="background-color: white; max-width:400px;height:auto;margin:auto;position: relative;">
            <div
                style="background-image:url(./assets/images/1013/img001.jpg);background-size:cover;background-repeat: no-repeat;height:400px;">
                <img [src]="contactInfo?.company_logo"  *ngIf="contactInfo?.company_logo"
                    style="width:150px;height:auto;object-fit:contain;display:block;padding:2rem;">
            </div>
            <img src="./assets/images/1013/a21.png" style="position:absolute;top:320px;right:0px;">
            <div class="grad" style="padding:30px;">
                <div style="display:flex;align-items:center;align-items:center;gap:30px;padding-top:70px;justify-content: center;">
                    <div *ngIf="contactInfo?.phone_number_1" style="display:block;text-align:center">
                        <a [href]="'tel:'+ contactInfo?.phone_number_1">
                        <img src="./assets/images/1013/01.png"style="width:55px;height:55px;object-fit:contain;margin:auto;">
                        <p
                            style="font-size:12px;color:#ffffff;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            CALL</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.whatsapp" style="display:block;text-align:center">
                        <a [href]="'https://wa.me/'+contactInfo?.whatsapp">
                        <img src="./assets/images/1013/02.png"style="width:55px;height:55px;object-fit:contain;margin:auto;">
                        <p
                            style="font-size:12px;color:#ffffff;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            WHATSAPP</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.email" style="display:block;text-align:center">
                        <a [href]="'mailto:'+ contactInfo?.email">
                        <img src="./assets/images/1013/03.png" style="width:55px;height:55px;object-fit:contain;margin:auto;">
                        <p
                            style="font-size:12px;color:#ffffff;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            E-MAIL</p>
                        </a>
                    </div>
                </div>
                <div style="display:flex;align-items:center;align-items:center;gap:30px;justify-content: center;">
                    <div *ngIf="contactInfo?.facebook" style="display:block;text-align:center">
                        <a [href]="'https://facebook.com/'+contactInfo?.facebook">
                        <img src="./assets/images/1013/04.png" style="width:55px;height:55px;object-fit:contain;margin:auto;">
                        <p
                            style="font-size:12px;color:#FFFFFF;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            FACEBOOK</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.instagram" style="display:block;text-align:center">
                        <a [href]="'https://instagram.com/'+contactInfo?.instagram">
                        <img src="./assets/images/1013/05.png" style="width:55px;height:55px;object-fit:contain;margin:auto;">
                        <p
                            style="font-size:12px;color:#ffffff;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            INSTAGRAM</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.twitter" style="display:block;text-align:center">
                        <a [href]="'https://twitter.com/'+contactInfo?.twitter">
                        <img src="./assets/images/1013/06.png" style="width:55px;height:55px;object-fit:contain;margin:auto;">
                        <p
                            style="font-size:12px;color:#FFFFFF;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            TWITTER</p>
                            </a>
                    </div>
                </div>
                <div *ngIf="contactInfo?.address" style="display:flex;align-items:center;justify-content:center;margin:auto;gap:12px;color:white;font-size:17px;margin-bottom:35px;margin-top:20px;">
                    <img src="./assets/images/1013/location.png" style="width:30px;height:30x;object-fit:contain">
                    <span style="font-size:15px;line-height:1;"> {{contactInfo?.address}}</span>
                </div>
                <div style="position:relative;left:-30px;bottom:-30px;">
                <img src="./assets/images/1013/a1.png" style="bottom:0px;position:absolute;width:80%;object-fit:contain;">
            </div>
            </div>
           
        </div>


    </section>

</body>

</html>