import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, delay, map, Observable, of, ReplaySubject, tap, throwError } from 'rxjs';
import { Contact, EmailSignatureLatest, User, UserProfile } from 'app/core/user/user.types';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';
import { environment } from 'environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { ToastrService } from 'ngx-toastr';

declare function logoutIntercom(): any;
declare function closeMobileWebView()

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userProfile: ReplaySubject<UserProfile> = new ReplaySubject<UserProfile>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, private db: AngularFireDatabase,
        private _fuseConfirmationService: FuseConfirmationService,
        private toastr: ToastrService,
        private cookieService: CookieService, private spinner: NgxSpinnerService) {

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    set userProfile(value: UserProfile) {
        // Store the value
        this._userProfile.next(value);
    }
    get userProfile$(): Observable<UserProfile> {
        return this._userProfile.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    logOut() {
        this.afAuth.signOut()
        this.deleteTokenCookie()
        logoutIntercom()
        closeMobileWebView()
    }

    deleteTokenCookie() {
        this.cookieService.deleteAll()

        let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
        this.cookieService.set('token', '', { domain: DOMAIN, sameSite: 'None', expires: new Date(1970, 1, 1), path: '', secure: true })
        // // if (environment.production)
        // this.cookieService.delete("customToken", '/', 'printingwarehouses.com', true, 'None',)
        // // else
        // this.cookieService.delete("customToken", '/', 'printingw.xyz', true, 'None',)
    }
    ////////////////////////
    // API Calls

    isLoggedIn(): Boolean {
        return this.cookieService.get("token") != null && this.cookieService.get("token").length > 0
    }

    get(): Observable<User> {
        return this._httpClient.get<User>(`${environment.BASE_URL}/user/profile/`).pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    private _userProfiles: BehaviorSubject<UserProfile[]> = new BehaviorSubject<UserProfile[]>(null);
    get userProfiles$(): Observable<UserProfile[]> {
        return this._userProfiles.asObservable();
    }

    getUserProfiles() {
        return this._httpClient.get<UserProfile[]>(`${environment.BASE_URL}/vid/profile/`).pipe(
            tap((profiles) => {
                if (localStorage.getItem("current_profile") == null || localStorage.getItem("current_profile")?.length == 0) {
                    localStorage.setItem("current_profile", profiles[0].profile_id)
                    localStorage.setItem("current_profile_code", profiles[0].public_share_code)
                }
                this._userProfiles.next(profiles);

                let currentProfile = profiles.find(profile => {
                    return profile.profile_id == localStorage.getItem("current_profile")
                })

                if (currentProfile == null) {
                    localStorage.setItem("current_profile", profiles[0].profile_id)
                    localStorage.setItem("current_profile_code", profiles[0].public_share_code)
                    currentProfile = profiles[0]
                } else {
                    localStorage.setItem("current_profile_code", currentProfile.public_share_code)
                }

                this._userProfile.next(currentProfile)
                if (currentProfile != null) {
                    this.getUserProfileFromServer(currentProfile.profile_id).subscribe()
                    this.getUserEmailtemplate(currentProfile.profile_id).subscribe()
                }
            }), catchError((err) => {
                let response = JSON.parse(err.error);
                this.showSubscriptionErrorDialog(null, response.message, response.url)
                return of(null)
            })
        );
    }



    private currentEmailSignature: ReplaySubject<EmailSignatureLatest> = new ReplaySubject<EmailSignatureLatest>(1);
    get currentEmailSignature$(): Observable<EmailSignatureLatest> {
        return this.currentEmailSignature.asObservable()
    }
    getUserEmailtemplate(profileId) {
        return this._httpClient.get<EmailSignatureLatest>(`${environment.BASE_URL}/email_signature/my-templates/0/`).pipe(
            tap((signature) => {
                this.currentEmailSignature.next(signature.template_image == null ? null : signature);
            })
        );
    }
    updateEmailtemplateImage(profileId, data) {
        return this._httpClient.post<any>(`${environment.BASE_URL}/email_signature/my-templates/`, data)
    }

    private currentProfile: ReplaySubject<UserProfile> = new ReplaySubject<UserProfile>(1);
    get currentProfile$(): Observable<UserProfile> {
        return this.currentProfile.asObservable()
    }

    //for resolver only
    getUserProfileFromServer(profileId) {
        return this._httpClient.get<UserProfile>(`${environment.BASE_URL}/vid/profile/${profileId}/`).pipe(
            tap((profile) => {
                this.currentProfile.next(profile);
            })
        );
    }

    getUserProfileDetails(profileId) {
        return this._httpClient.get<UserProfile>(`${environment.BASE_URL}/vid/profile/${profileId}/`)
    }

    getPublicProfile(sharecode) {
        return this._httpClient.get<Contact>(`${environment.BASE_URL}/vid/public_share/${sharecode}/data/`)
    }

    downloadCard(id) {
        window.location.href = `${environment.BASE_URL}/vid/get_vcf/${id}/`;
    }

    submitUserQuery(data) {
        return this._httpClient.post<any>(`${environment.BASE_URL}/user/contact-us/submit/`, {
            "name": data.first_name + " " + data.last_name,
            "email": data.email,
            "message": data.message,
            "phone": data.phone_number
        })
    }

    updateImages(profileId, data) {
        return this._httpClient.put<any>(`${environment.BASE_URL}/vid/profile/${profileId}/`, data)
    }

    removeProfileImage(profileId, isProfilePic) {
        let data = new FormData()
        data.append(isProfilePic ? 'remove_profile_image' : 'remove_company_logo', "1")
        return this._httpClient.put<any>(`${environment.BASE_URL}/vid/profile/${profileId}/`, data)
    }

    createProfile(data) {
        return this._httpClient.post<any>(`${environment.BASE_URL}/vid/profile/`, data)
    }

    updateProfile(profileId, data) {
        return this._httpClient.put<any>(`${environment.BASE_URL}/vid/profile/${profileId}/`, data).pipe(tap(res => {
            this._userProfiles.value.forEach(profile => {
                if (profile.profile_id == profileId) {
                    profile.profile_picture = res.profile_data?.profile_picture
                }
            })
        }))
    }

    deleteProfile(profileId) {
        return this._httpClient.delete<any>(`${environment.BASE_URL}/vid/profile/${profileId}/`).pipe(
            tap((res) => {
                this.getUserProfiles().subscribe()
            })
        );
    }


    showSubscriptionErrorDialog(title?: string, message?: string, url?: string) {
        let config: FuseConfirmationConfig = {
            title: title ? title : 'Subscription',
            message: message ? message : `It seems that you do not have a subscription to use this service. Please subscribe now to get access.`,
            icon: {
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'OK',
                    color: 'warn'
                },
                cancel: {
                    show: false,
                }
            },
            dismissible: false
        };
        this._fuseConfirmationService.open(config).afterClosed().subscribe(value => {
            closeMobileWebView()
            if (value == 'confirmed' && url != null) {
                window.location.href = url ? url : environment.subscriptionUrl
            }
        })
    }

}
