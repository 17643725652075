<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Prompt:wght@300&display=swap" rel="stylesheet">
    <style>
        .businesscard {

            background-color: #ffffff;
        }

        .grad {
            margin-top:-115px;
            padding-left:45px;
            padding-right:45px;
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="background-color: white;min-width: 250px; max-width:400px;height:auto;margin:auto;position: relative;">
            <div>
                <img src="./assets/images/1012/bg.jpg" style="position:relative;width:100%;height:auto;clip-path: polygon(0 0%, 100% 0%, 100% 75%, 0 35%);top:0px;left:0px;">
                <img [src]="contactInfo?.company_logo" style="width:100px;height:100px;object-fit:contain;position: absolute;top:50px;left:40%;">
                <img src="./assets/images/1012/line.png" style="position:absolute;top:165px;left:0px;width:100%;object-fit: contain;">
                <div style="position:absolute;top:300px;left:45px;width:140px;height:140px;border-radius:100%;object-fit:contain;border:3px solid #943961;">
                    <img [src]="contactInfo?.profile_picture" *ngIf="contactInfo?.profile_picture" style="width:100%;height:100%;border-radius:100%;object-fit: cover;">
                </div>
            </div>
            <div class="grad">
                <p style="color:#3C3E43;font-weight:bold;font-size:28px;margin:0px">{{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="color:#3C3E43;font-weight:100;font-size:20px;margin:0px;font-family: 'Prompt', sans-serif;line-height:0.5;">{{contactInfo?.designation}}</p>
                <hr style="border:1px solid #943961;margin:30px 0px;margin-bottom:0px;">
                <div style="padding:15px 20px;padding-top:9px;background-color:#943961;text-align:center;width:200px;margin:auto;">
                    <P style="font-size:14px;color:white;font-weight:bold;margin:0px;">FOR MORE DETAILS</P>
                    <P style="font-size:17px;color:white;font-weight:bolder;margin:0px;">TOUCH ANY ICON</P>
                    <img src="https://cdn.pwa.ink/assets/1qj5/arrow_mark.png" style="display:block;margin:auto;margin-bottom:-35px;width:30px;height:30px;object-fit:contain">
                </div>
                <div style="display:flex;align-items:center;align-items:center;gap:20px;padding-top:40px;justify-content: center;">
                    <div *ngIf="contactInfo?.phone_number_1" style="display:block;text-align:center">
                        <a [href]="'tel:'+ contactInfo?.phone_number_1">
                        <img src="./assets/images/1012/phone.png" style="width:55px;height:55px;object-fit:contain;margin:auto">
                        <p
                            style="font-size:15px;color:#34438f;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            CALL</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.whatsapp" style="display:block;text-align:center">
                        <a [href]="'https://wa.me/'+contactInfo?.whatsapp">
                        <img src="./assets/images/1012/whatsapp.png" style="width:55px;height:55px;object-fit:contain;margin:auto">
                        <p
                            style="font-size:15px;color:#34438f;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            WHATSAPP</p>
                         </a>
                    </div>
                    <div *ngIf="contactInfo?.email" style="display:block;text-align:center">
                        <a [href]="'mailto:'+ contactInfo?.email">
                        <img src="./assets/images/1012/email.png" style="width:55px;height:55px;object-fit:contain;margin:auto">
                        <p
                            style="font-size:15px;color:#34438f;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            E-MAIL</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.website" style="display:block;text-align:center">
                        <a [href]="contactInfo?.website">
                        <img src="./assets/images/1012/web.png" style="width:55px;height:55px;object-fit:contain;margin:auto">
                        <p
                            style="font-size:15px;color:#34438f;font-family:poppins;margin:0px;text-align: center;line-height: 3;">
                            WEB</p>
                        </a>
                    </div>
                </div>
                <hr style="border:1px solid #943961;margin:10px 0px;margin-bottom:0px;">
                <div style="display:flex;gap:15px;align-items: center;justify-content: center;padding-top:20px;padding-bottom:30px;">
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram">
                    <img src="./assets/images/1012/instagram.png" style="width:25px;height:25px;object-fit:contain;">
                    </a>
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook">
                    <img src="./assets/images/1012/facebook.png" style="width:25px;height:25px;object-fit:contain;">
                    </a>
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter">
                    <img src="./assets/images/1012/twitter.png" style="width:25px;height:25px;object-fit:contain;">
                    </a>
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin">
                    <img src="./assets/images/1012/linkedin.png" style="width:25px;height:25px;object-fit:contain;">
                    </a>
                    <a [href]="'https://youtube.com/'+contactInfo?.youtube" *ngIf="contactInfo?.youtube">
                    <img src="./assets/images/1012/youtube.png" style="width:25px;height:25px;object-fit:contain;">
                    </a>

                </div>
            </div>
           
        </div>


    </section>

</body>

</html>