<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet">

</head>

<body>
    <section>
        <div 
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;padding-bottom:3rem;background-color: white;;">

            <!-- business card -->

            <div class="row" style="display: flex;">
                <div class="col" style="display:flex;margin:auto;width:80px;">
                    <img *ngIf="contactInfo?.company_logo" [src]="contactInfo?.company_logo" style="width:100px;height:100px;object-fit: contain;margin:0px;">
                </div>
                <div class="col">
                    <img src="./assets/images/1020/shape.png">

                </div>
            </div>
            <div  *ngIf="contactInfo?.profile_picture" 
                style="display:flex;margin:auto;border:2px solid #dbdadb;padding:2px;border-radius:100px;display:flex;justify-content: center;width:fit-content;position: absolute;top:220px;right:150px;">
                <img s [src]="contactInfo?.profile_picture" style="width:120px;height:120px;border-radius:100%;object-fit: cover;">
            </div>

            <!-- profile data -->
            <!-- Profie data -->
            <div style="padding-left:20px;padding-right:20px;">
                <div style="margin-top:25px;font-family: 'Varela Round', sans-serif;">
                    <p
                        style="font-size:25px;margin:0px;color:#19459d;font-weight:bolder;font-family: 'Raleway', sans-serif;line-height: normal;">
                        {{contactInfo?.first_name}} {{contactInfo?.middle_name}} <span style="color:#4d4d4f">{{contactInfo?.last_name}}</span>
                    </p>
                    <p
                        style="font-size:20px;margin:0px;color:#4d4d4f;font-family: 'Montserrat', sans-serif;margin-top:0px;line-height: normal;">
                        {{contactInfo?.designation}}</p>
                    <p
                        style="color:#4d4d4f;font-family: 'Montserrat', sans-serif;;font-size:20px;margin-top:3px;margin-bottom:7px;line-height: 0.7;">
                        {{contactInfo.company}}</p>
                </div>
            </div>

            <!-- About me -->
            <div style="padding-left:20px;padding-right:20px;">
                <p *ngIf="contactInfo?.description_title"
                    style="color:#4d4d4f;font-size:20px;font-family: 'Varela Round', sans-serif;font-weight:100;margin-bottom:0px;">
                    {{contactInfo?.description_title}}</p>
                <p *ngIf="contactInfo?.description"
                    style="color:#4d4d4f;line-height:1.5;font-size:14px;font-family: 'Montserrat', sans-serif;margin-top:7px;">
                    {{contactInfo?.description}}</p>
            </div>



            <!-- Details -->

            <div style="margin-top:2rem;margin-bottom:2.5rem;padding:0px 20px;">
                <div  *ngIf="contactInfo?.address" style="display: flex;margin-right:20px;margin-bottom:1rem;">
                    <div
                        style="display:flex;align-items:center;width:30px;height:30px;border-radius:30px;background-color:#19459d;display: flex;justify-content: center;padding:7px;flex-shrink: 0;">
                        <img src="./assets/images/1020/location.png">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
                </div>
                <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1" style="display: flex;margin-right:20px;margin-bottom:1rem;">
                    <div
                        style="display:flex;align-items:center;width:30px;height:30px;border-radius:30px;background-color:#19459d;display: flex;justify-content: center;padding:7px;flex-shrink: 0;">
                        <img src="./assets/images/1020/call.png">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.phone_number_1}}</span>
                </a>
                <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email" style="display: flex;margin-right:20px;margin-bottom:1rem;">
                    <div
                        style="display:flex;align-items:center;width:30px;height:30px;border-radius:30px;background-color:#19459d;display: flex;justify-content: center;padding:7px;flex-shrink :0;">
                        <img src="./assets/images/1020/email.png">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
                </a>

                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website" style="display: flex;margin-right:20px;margin-bottom:1rem;">
                    <div
                        style="display:flex;align-items:center;width:30px;height:30px;border-radius:30px;background-color:#19459d;display: flex;justify-content: center;padding:7px;flex-shrink:  0;">
                        <img src="./assets/images/1020/web.png">
                    </div>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.website}}</span>
                </a>

            </div>
        </div>
    </section>
</body>

</html>