<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">


</head>

<body style="background-color: transparent !important;">
    <section style="background-color:white;">

        <!-- profile image -->
        <div class="bussinesscard"
            style="width:100%; max-width:400px;height:auto;margin:auto;padding-bottom:3rem;background-color:white;">
            <div style="padding-top:100px;">
                <img [src]="contactInfo?.profile_picture" *ngIf="contactInfo?.profile_picture"
                    style="object-fit: cover; height: 150px; width: 150px; border:3px solid rgba(221, 227, 240);border-radius:50%;padding:3px;display: block;margin:auto;">
            </div>

            <!--  Profile datas -->
            <div style="text-align:center;margin-top:20px;margin-bottom:30px ;">
                <p
                    style="font-size:28px;margin:0px;color:#4d4d4f;font-weight:600;color:#4d4d4f;font-family: 'Varela Round', sans-serif;">
                    {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="font-size:22px;margin:0px;color:#606060;font-family: 'Poppins', sans-serif;">
                    {{contactInfo?.designation}}</p>
            </div>
            <!-- About me -->
            <div style="padding-left:30px;padding-right:20px;margin-top:1.5rem;margin-bottom:1.5rem;">
                <p style="color:#4d4d4f;font-size:17px;font-weight:bold;font-family: 'Varela Round', sans-serif;font-weight:400;font-family: 'Poppins', sans-serif;"
                    *ngIf="contactInfo?.description_title">{{contactInfo?.description_title}}</p>
                <p style="color:#4d4d4f;line-height:1.5;font-size:17px;font-weight:100;font-family: 'Montserrat', sans-serif;"
                    *ngIf="contactInfo?.description">{{contactInfo?.description}}</p>
            </div>
            <!-- company logo -->
            <div style="margin-left:30px;object-fit:contain;margin-bottom:2rem;">
                <img [src]="contactInfo?.company_logo" style="max-height:80px;max-width:180px;object-fit: contain;width: auto;" *ngIf="contactInfo?.company_logo">
                <p style="font-size:17px;margin-top:7px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight: 400;"
                    *ngIf="contactInfo?.company">
                    {{contactInfo.company}}</p>
            </div>

            <!-- Details -->

            <div
                style="background-color:#f0f0f0;margin-left:25px;margin-right:25px;margin-bottom:35px;border-radius:10px;padding-top:35px;padding-bottom:35px;">
                <div *ngIf="contactInfo?.address"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconLocation"
                        style="padding:13px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:17px;font-family: 'Poppins', sans-serif;">
                        {{contactInfo?.address}}
                    </span>
                </div>
                <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconPhone"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.phone_number_1}}</span>
                </a>
                <a style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                    *ngIf="contactInfo?.phone_number_2" [href]="'tel:'+ contactInfo?.phone_number_2">
                    <fa-icon [icon]="iconTelephone"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                        {{contactInfo?.phone_number_2}}</span>

                </a>
                <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconEmail"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.email}}</span>
                </a>

                <a *ngIf="contactInfo?.website" style="display: flex;margin-left:30px;margin-right:30px;"
                    [href]="contactInfo?.website">
                    <fa-icon [icon]="iconWeb"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#606060;font-size:17px;font-family: 'Poppins', sans-serif;">{{contactInfo?.address}}</span>
                </a>

            </div>

            <!-- icons -->
            <div>
                <p *ngIf="contactInfo?.twitter"
                    style="align-items: center;color:#606060;font-size:13px;font-family: 'Poppins', sans-serif;text-align: center;margin:0px;line-height: 2;">
                    @{{contactInfo?.twitter}}</p>
                <div class="flex flex-row justify-center gap-3">

                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconFacebook" class="smmIcon"></fa-icon>
                    </a>
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconInstagram" class="smmIcon"></fa-icon>
                    </a>
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconTwitter" class="smmIcon"></fa-icon>
                    </a>
                    <a [href]="'https://pinterest.com/'+contactInfo?.pinterest" *ngIf="contactInfo?.pinterest"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconPinterest" class="smmIcon"></fa-icon>
                    </a>
                    <a [href]="'https://youtube.com/'+contactInfo?.youtube" *ngIf="contactInfo?.youtube"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconYouTube" class="smmIcon"></fa-icon>
                    </a>
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconLinkedIn" class="smmIcon"></fa-icon>
                    </a>
                    <a [href]="'https://wa.me/'+contactInfo?.whatsapp" *ngIf="contactInfo?.whatsapp"
                        style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        <fa-icon [icon]="iconWhatsapp" class="smmIcon"></fa-icon>
                    </a>

                </div>
            </div>


        </div>


    </section>
</body>

</html>