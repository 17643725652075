<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>
        .businesscard {

            background-color: #ffffff;
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="width: 100%; max-width: 400px;height:auto;margin:auto;background-color: white;position: relative;">
            <img src="./assets/images/1009/img.jpg" style="width:100%;height:auto;object-fit:contain;">
            <div style="position:absolute;margin:20px 20px;top:15px;">
                <!-- company logo -->
                <img [src]="contactInfo?.company_logo"  *ngIf="contactInfo?.company_logo" style="width:100px;height:100px;object-fit:contain;margin-bottom:10px;">
                <!--  -->
                <!-- datas -->
                <div style="display:grid;gap:15px;">
                    <a *ngIf="contactInfo?.phone_number_1" [href]="'tel:'+ contactInfo?.phone_number_1">
                    <img src="./assets/images/1009/01.png" style="width:80px;height:80px;object-fit:contain;">
                    </a>
                    <a *ngIf="contactInfo?.whatsapp" [href]="'https://wa.me/'+contactInfo?.whatsapp">
                    <img src="./assets/images/1009/02.png" style="width:80px;height:80px;object-fit:contain;">
                    </a>
                    <a *ngIf="contactInfo?.email" [href]="'mailto:'+ contactInfo?.email">
                    <img src="./assets/images/1009/03.png" style="width:80px;height:80px;object-fit:contain;">
                    </a>
                    <a *ngIf="contactInfo?.website" [href]="contactInfo?.website">
                    <img src="./assets/images/1009/04.png" style="width:80px;height:80px;object-fit:contain;">
                    </a>
                </div>
                <!--  -->

            </div>
            <div class="grad">
                <img src="./assets/images/1009/curve.png" style="width:100%;height:auto;object-fit:contain;margin-top:-300px;">
                <div
                    style="display:flex;justify-content:center;gap:10px;position: absolute;bottom:0px;align-items: center;padding:20px 50px;">
                    <img src="https://cdn.pwa.ink/assets/UjcN/location_new.png" style="width:27px;height:27px;object-fit:contain;flex-shrink:0;">
                    <p style="font-size:15px;font-weight:normal;color:white;line-height: 1;">{{contactInfo?.address}}</p>
                </div>
                <div
                    style="display:flex;gap:10px;justify-content:end;align-items:center;margin:1.2rem 0rem;position:absolute;bottom:0px;flex-direction:column;right:20px;">
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook">
                    <img src="./assets/images/1009/fb.png" style="width:25px;height:25px;object-fit:contain">
                    </a>
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram">
                    <img src="./assets/images/1009/instagram.png" style="width:25px;height:25px;object-fit:contain">
                    </a>
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter">
                    <img src="./assets/images/1009/twitter.png" style="width:25px;height:25px;object-fit:contain">
                    </a>
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin">
                    <img src="./assets/images/1009/linkedin.png" style="width:25px;height:25px;object-fit:contain">
                    </a>
                    <a [href]="'https://youtube.com/'+contactInfo?.youtube" *ngIf="contactInfo?.youtube">
                    <img src="./assets/images/1009/youtube.png" style="width:25px;height:25px;object-fit:contain">
                    </a>
                </div>
            </div>

        </div>
    </section>

</body>

</html>