<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">


    <style>
        .grad {
            background-color: #01c0cd;
            background-image: -webkit-linear-gradient(-250deg, #6c38e9 -10%, #01c0cd 100%);
            min-height: 300px;
            clip-path: polygon(0 0%, 100% 0, 100% 60%, 0 100%);
        }

        .profile {
            position: absolute;
            width: 50%;
            left: 50%;
            top: 100px;
            transform: translate(-50%);
            text-align: center;
        }
    </style>
</head>

<body style="background-color: transparent !important;">
    <section>
        <div class="businesscard"
            style="width: 100%; max-width: 400px;height:auto;margin:auto;background-color: white;">
            <div style="position:relative">
                <div class="grad" style="width:100%;">

                </div>
                <!-- Profile image -->

                <div style="position:absolute;top:50%;left:30%;">
                    <img [src]="contactInfo?.profile_picture" *ngIf="contactInfo?.profile_picture"
                        style="border:3px solid rgba(221, 227, 240);border-radius:50%;padding:3px;height:100%;width: 150px;height: 150px;object-fit: cover;">
                </div>
            </div>
            <!-- Profie data -->
            <div style="text-align:center;margin-top:25px;">
                <p
                    style="font-size:28px;margin:0px;color:#4d4d4f;font-weight:600;font-family: 'Poppins', sans-serif;line-height: 1;">
                    {{contactInfo?.first_name}} {{contactInfo?.middle_name}} {{contactInfo?.last_name}}</p>
                <p style="font-size:20px;margin:0px;color:#01c0cd;font-family: 'Poppins', sans-serif;font-weight: 500;">
                    {{contactInfo?.designation}}</p>
            </div>

            <!-- icons -->
            <div class="w-full flex flex-row justify-center p-8 gap-2">
                <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook"
                    style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                    <fa-icon [icon]="iconFacebook" class="smmIcon"></fa-icon>
                </a>
                <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram"
                    style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                    <fa-icon [icon]="iconInstagram" class="smmIcon"></fa-icon>
                </a>
                <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter"
                    style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                    <fa-icon [icon]="iconTwitter" class="smmIcon"></fa-icon>
                </a>
                <a [href]="'https://pinterest.com/'+contactInfo?.pinterest" *ngIf="contactInfo?.pinterest"
                    style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                    <fa-icon [icon]="iconPinterest" class="smmIcon"></fa-icon>
                </a>
                <a [href]="'https://youtube.com/'+contactInfo?.youtube" *ngIf="contactInfo?.youtube"
                    style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                    <fa-icon [icon]="iconYouTube" class="smmIcon"></fa-icon>
                </a>
                <a [href]="'https://linkedin.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin"
                    style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                    <fa-icon [icon]="iconLinkedIn" class="smmIcon"></fa-icon>
                </a>
                <a [href]="'https://wa.me/'+contactInfo?.whatsapp" *ngIf="contactInfo?.whatsapp"
                    style="padding:7px;border-radius:40px;width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                    <fa-icon [icon]="iconWhatsapp" class="smmIcon"></fa-icon>
                </a>

            </div>
            <!-- About me -->
            <div style="padding-left:30px;padding-right:20px;margin-top:1.5rem;margin-bottom:1.5rem;">
                <p style="color:#4d4d4f;font-size:17px;font-weight:bold;font-family: 'Varela Round', sans-serif;font-weight:400;font-family: 'Poppins', sans-serif;"
                    *ngIf="contactInfo?.description_title">{{contactInfo?.description_title}}</p>
                <p style="color:#4d4d4f;line-height:1.5;font-size:17px;font-weight:100;font-family: 'Montserrat', sans-serif;"
                    *ngIf="contactInfo?.description">{{contactInfo?.description}}</p>
            </div>

            <!-- company logo -->
            <div style="margin-left:30px;object-fit:contain;padding-bottom:2.5rem;">
                <img [src]="contactInfo?.company_logo" style="max-height:80px;max-width:180px;object-fit: contain;width: auto;"
                    *ngIf="contactInfo?.company_logo">
                <p style="font-size:17px;margin-top:7px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight: 400;"
                    *ngIf="contactInfo?.company">
                    {{contactInfo.company}}</p>
            </div>

            <!-- datas -->
            <div style="margin-bottom:3rem;">
                <a *ngIf="contactInfo?.phone_number_1"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;"
                    [href]="'tel:'+ contactInfo?.phone_number_1">
                    <fa-icon [icon]="iconPhone"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">{{contactInfo?.phone_number_1}}</span>
                </a>
                <a style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;align-items: center;"
                    *ngIf="contactInfo?.phone_number_2" [href]="'tel:'+ contactInfo?.phone_number_2">
                    <fa-icon [icon]="iconTelephone"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                        {{contactInfo?.phone_number_2}}</span>

                </a>
                <a *ngIf="contactInfo?.email"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;"
                    [href]="'mailto:'+ contactInfo?.email">
                    <fa-icon [icon]="iconEmail"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">{{contactInfo?.email}}</span>
                </a>
                <a *ngIf="contactInfo?.website" [href]="contactInfo?.website"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconWeb"
                        style="padding:7px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;color:#4d4d4f;font-size:17px;font-family: 'Poppins', sans-serif;font-weight:400;">{{contactInfo?.website}}</span>
                </a>
                <div *ngIf="contactInfo?.address"
                    style="display: flex;margin-left:30px;margin-right:30px;margin-bottom:1rem;">
                    <fa-icon [icon]="iconLocation"
                        style="padding:13px;border-radius:40px;width:35px;height:35px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                    <span
                        style="margin-left:20px;display: flex;align-items: center;font-size:17px;color:#4d4d4f;font-family: 'Poppins', sans-serif;font-weight:400">
                        {{contactInfo?.address}}
                    </span>
                </div>
            </div>
            <div style="background-color:#f0f0f0;padding-top:2rem;padding-bottom:2rem;">
                <p *ngIf="contactInfo?.twitter"
                    style="align-items: center;color: #606060;;font-size:13px;font-family: 'Poppins', sans-serif;text-align: center;margin:0px;line-height: 2;">
                    @{{contactInfo?.twitter}}</p>
            </div>

        </div>

    </section>

</body>

</html>