<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-white print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <img src="./assets/images/logo.svg" style="width:130px;height:auto;cursor: pointer;" [routerLink]="['/']">
        </div>
        <button class="flex sm:hidden mr-2 ml-2" *ngIf="this._userService.isLoggedIn()" mat-button
            [matMenuTriggerFor]="navProfileMenu"
            style="color: black; align-items: center;font-family:helvetica;font-size:13px;justify-content: center;align-items: center;">

            <div class="truncate w-full">
                {{this.user?.name | uppercase }}
            </div>
            <div class="ml-1">
                <fa-icon [icon]="iconArrowDown"
                    style="color: #2b2172; width:15px;height:15px;justify-content: center;display: flex;align-items: center;">
                </fa-icon>
            </div>

        </button>
        <mat-menu #navProfileMenu="matMenu">
            <button mat-menu-item (click)="onClickLogin()" style="font-family:helvetica">
                <img src="./assets/images/logout.svg" style="height: 20px; width: 20px;"><span
                    style="margin-left:20px;">LOGOUT</span></button>
            <!-- <button mat-menu-item>Item 2</button> -->
        </mat-menu>
    </ng-container>
    <!-- Navigation footer hook -->
    <!-- <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                class="max-w-36"
                src="assets/images/logo/logo-text-on-dark.svg">
        </div>
    </ng-container> -->
</fuse-vertical-navigation>
<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0" style="background-color:white;">

    <!-- Header -->
    <div [ngClass]="showHeaderDivider ? 'active' : ''"
        class="header navigation_bar relative flex flex-0 items-center justify-between w-full z-49 pt-[25px] pb-[25px] md:pt-[25px] md:pb-[25px] dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden pl-5 pr-5 sm:pl-15 md:pl-25 sm:pr-15 md:pr-25 lg:pr-30 lg:pl-30">
        <div class="flex" style="align-items:center;">
            <button class="toggle_btn mr-5 block md:hidden" mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon class="mat-iconn" [svgIcon]="'heroicons_outline:menu'"
                    style="width:35px;height:35px;margin-right:20px;">
                </mat-icon>
            </button>
            <img src="./assets/images/logo.svg" class="w-[85px] md:w-[120px]" style="height:auto;cursor: pointer;"
                [routerLink]="['/']">
        </div>
        <div class="flex flex-row items-center">
            <div class="hidden navigation_lg md:flex">
                <div>
                <button class="activebtn"  style="font-family: 'Poppins', sans-serif;margin-right: 30px;font-size:14px;"
                    routerLink="/products"
                    [ngStyle]="{'font-weight': this._router.url==('/products') ? 'bold' : 'normal',  'color': this._router.url==('/products') ? '#2b2172' : '#000000'}">Virtual Biz Cards
                   
                </button>
                <div class="border1" [ngStyle]="{'border': this._router.url==('/products') ? '2px solid #68b24d' : '2px solid white'}" style="width:30px;border:2px solid white;border-radius:100px;"></div>
            </div>
            <div>
                <button class="activebtn" style="font-family: 'Poppins', sans-serif;margin-right: 30px;font-size:14px;"
                    routerLink="/email-signatures"
                    [ngStyle]="{'font-weight': this._router.url==('/email-signatures') ? 'bold' : 'normal',  'color': this._router.url==('/email-signatures') ? '#2b2172' : '#000000'}">Email
                    Signatures
                </button>
                <div class="border1" [ngStyle]="{'border': this._router.url==('/email-signatures') ? '2px solid #68b24d' : '2px solid white'}" style="width:30px;border:2px solid white;border-radius:100px;"></div>
            </div>
            <div>
                <button class="activebtn" style="font-family: 'Poppins', sans-serif;margin-right: 30px;font-size:14px"
                    (click)="openUrlInNewTab('https://printingwarehouses.com/pricing');">Pricing
                </button>
                <div class="border1" style="width:30px;border:2px solid white;border-radius:100px;"></div>
            </div>
            </div>

            <div class="hidden md:hidden"
                style="border:1px solid black;box-shadow: 0 3px 21px 0 rgba(199, 199, 199, 0.16);background-color: #fff;height:20px;margin-right:40px;">
            </div>

            <button class="flex mr-4" *ngIf="!this._userService.isLoggedIn()"
                style="align-items: center;font-family: 'Poppins', sans-serif;font-size:14px;justify-content: center;align-items: center;"
                (click)="onClickLogin()">
                <img src="./assets/images/banner_user.svg" style="width:25px;height:25px;"><span
                    style="margin-left:10px;">LOGIN</span>
            </button>
            <button class="hidden sm:flex mr-2" *ngIf="this._userService.isLoggedIn()" mat-button
                [matMenuTriggerFor]="menu"
                style="align-items: center;font-family: 'Poppins', sans-serif;font-size:14px;justify-content: center;align-items: center;">
                <img [src]="this.user.profile_image ? this.user.profile_image : './assets/images/user.png'"
                    style="width:24px;height:24px;object-fit: cover;border-radius: 12px;"><span
                    style="margin-left:10px;">{{this.user.name | uppercase }}</span>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onClickLogin()" style="font-family: 'Poppins', sans-serif;">
                    <img src="./assets/images/logout.svg" style="height: 20px; width: 20px;"><span
                        style="margin-left:20px;">LOGOUT</span></button>
                <!-- <button mat-menu-item>Item 2</button> -->
            </mat-menu>
            <!-- Account Switch -->
            <button class="flex items-center" mat-stroked-button *ngIf="this._userService.isLoggedIn()" mat-button
                [matMenuTriggerFor]="profileMenu"
                style="font-family: 'Poppins', sans-serif;font-size:13px;border:2px solid #2b2172 !important;background-color:white;color:#2b2172 !important;font-weight:bold;">
                <div class="truncate max-w-[75px] md:max-w-[100px] lg:max-w-[150px]"
                    style="font-weight:bold;color:#2b2172 !important;font-family: 'Poppins', sans-serif;">
                    {{this.userProfile?.profile_name | uppercase }}
                </div>
                <div class="ml-6">
                    <fa-icon [icon]="iconArrowDown"
                        style="color: #2b2172; width:15px;height:15px;justify-content: center;display: flex;align-items: center;">
                    </fa-icon>
                </div>
            </button>
            <mat-menu #profileMenu="matMenu">
                <button mat-menu-item *ngFor="let profile of userProfiles" class="flex flex-row items-center"
                    style="font-family:helvetica; font-size: 12px;min-width: 200px;" (click)="switchAccount(profile)">

                    <div>
                        <img *ngIf="profile.profile_picture" [src]="profile.profile_picture" class="mr-3"
                            style="color: #2b2172;border-radius: 12px; width:24px;height:24px;object-fit: cover;">
                        <fa-icon *ngIf="!profile.profile_picture" [icon]="iconUser" class="mr-3"
                            style="font-size: 12px;padding: 6px; color: #2b2172; width:25px;height:25px;">
                        </fa-icon>
                    </div>
                    <div class="grow truncate">{{profile.profile_name}}</div>
                    <div class="ml-2">
                        <fa-icon [icon]="iconChecked" class="mr-3 flex sm:hidden"
                            *ngIf="this.userProfile?.profile_id == profile.profile_id"
                            style="color: #2b2172; width:15px;height:15px;">
                        </fa-icon>
                    </div>


                </button>
                <button mat-menu-item class="bg-[#f8f8ff]" routerLink="/profiles"
                    style="font-family:helvetica; font-size: 12px;justify-content: center;min-width: 200px;font-weight: bold;color: #2b2172;">MANAGE
                    PROFILES</button>
            </mat-menu>
            <!-- Account Switch end -->
            <button class="bg-[#2b2172] hidden sm:flex " routerLink="/products" *ngIf="!this._userService.isLoggedIn()"
                style="width:auto;color:white;text-align:center;border:0px;padding:13px 45px;border-radius:32px;font-size:12px;font-family:helvetica-bold;justify-content: center;">CREATE
                YOUR CARD</button>
        </div>
    </div>

    <!-- Content -->
    <div class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto mt-10 md:mt-20" (scroll)="scrollHandler($event)"
        cdkScrollable>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>

        <!-- Footer -->
        <div
            class="relative flex flex-0 items-center justify-start w-full z-49 border-t bg-card dark:bg-transparent print:hidden">
            <!-- Footer session starts -->
            <div
                class="flex-row hidden sm:hidden md:hidden lg:flex xl:flex justify-between bg-[#000000] p-[70px] pl-[137px] pr-[145px] w-full">
                <div class="footer_logo_row">
                    <img class="logo_img" src="./assets/images/footer_logo.svg"
                        style="width:130px;height:auto;margin-bottom:17px;">
                    <p class="CopyrightContent"
                        style="font-size:13px;font-family: 'Varela Round', sans-serif;opacity:51%;color:white;">© 2023
                        Printing
                        Warehouses of America, Inc. All rights reserved.</p>
                </div>
                <div>
                    <div class="flex flex-col sm:flex-row text-right justify-end footer_menu" style="gap:2rem;">
                        <a class="text-white Footer-nav-text" style="font-size:13px;cursor: pointer;"
                            [routerLink]="['/']"
                            [ngStyle]="{'font-weight': this._router.url==('/home') ? 'bold' : 'normal', 'color': this._router.url==('/home') ? '#2b2172' : '#000000'}">HOME</a>
                        <a class="text-white Footer-nav-text"
                            style="font-size:13px;cursor: pointer;font-family:helvetica;" [routerLink]="['/products']"
                            [ngStyle]="{'font-weight': this._router.url==('/products') ? 'bold' : 'normal', 'color': this._router.url==('/products') ? '#2b2172' : '#000000'}">VIRTUAL BIZ CARDS</a>
                        <a class="text-white Footer-nav-text"
                            style="font-size:13px;cursor: pointer;font-family:helvetica;"
                            [routerLink]="['/email-signatures']"
                            [ngStyle]="{'font-weight': this._router.url==('/email-signatures') ? 'bold' : 'normal', 'color': this._router.url==('/email-signatures') ? '#2b2172' : '#000000'}">EMAIL
                            SIGNATURES</a>
                        <a class="text-white Footer-nav-text"
                            style="font-size:13px;cursor: pointer;font-family:helvetica;"
                            (click)="openUrlInNewTab('https://printingwarehouses.com/pricing');">PRICING</a>
                        <!-- <a class="text-black Footer-nav-text" style="font-size:13px;cursor: pointer;" [routerLink]="['/']">BLOG</a> -->
                        <!-- <a class="text-black Footer-nav-text" style="font-size:13px;cursor: pointer;font-family:helvetica;"
                    [routerLink]="['/contactus']"
                    [ngStyle]="{'font-weight': this._router.url==('/contactus') ? 'bold' : 'normal',  'color': this._router.url==('/contactus') ? '#2b2172' : '#000000'}">CONTACT</a> -->
                    </div>
                    <div class="flex justify-end gap-3" style="margin-top:17px;">
                        <a href="https://twitter.com/printingwa/" target="_blank">
                            <img src="./assets/images/twitter_icon.png" class="footer-icons"
                                style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">

                        </a>
                        <a href="https://www.instagram.com/printingwarehouses/" target="_blank">
                            <img src="./assets/images/instagram.png" class="footer-icons"
                                style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">

                        </a>
                        <a href="https://www.facebook.com/printingwarehouses/" target="_blank">
                            <img src="./assets/images/fb_iocn.png" class="footer-icons"
                                style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">

                        </a>
                    </div>
                </div>
            </div>
            <!-- footer session for ipad only -->
            <div
                class="hidden lg:hidden md:grid footer bg-[#000000] p-[70px] pl-[137px] pr-[145px]  grid-cols-1 w-full">
                <div class="footer_logo_row flex">
                    <img class="logo_img" src="./assets/images/logo.svg"
                        style="width:130px;height:auto;margin-bottom:17px;">

                    <div style="width:100%;">
                        <div class="flex flex-col sm:flex-row text-right justify-end footer_menu" style="gap:2rem;">
                            <a class="text-white Footer-nav-text" style="font-size:13px;cursor: pointer;"
                                [routerLink]="['/']"
                                [ngStyle]="{'font-weight': this._router.url==('/home') ? 'bold' : 'normal', 'color': this._router.url==('/home') ? '#2b2172' : '#000000'}">HOME</a>
                            <a class="text-white Footer-nav-text"
                                style="font-size:13px;cursor: pointer;font-family:helvetica;"
                                [routerLink]="['/email-signatures']"
                                [ngStyle]="{'font-weight': this._router.url==('/email-signatures') ? 'bold' : 'normal', 'color': this._router.url==('/email-signatures') ? '#2b2172' : '#000000'}">EMAIL
                                SIGNATURES</a>
                            <a class="text-white Footer-nav-text"
                                style="font-size:13px;cursor: pointer;font-family:helvetica;"
                                [routerLink]="['/products']"
                                [ngStyle]="{'font-weight': this._router.url==('/products') ? 'bold' : 'normal', 'color': this._router.url==('/products') ? '#2b2172' : '#000000'}">VIRTUAL BIZ CARDS</a>
                            <a class="text-white Footer-nav-text"
                                style="font-size:13px;cursor: pointer;font-family:helvetica;"
                                (click)="openUrlInNewTab('https://printingwarehouses.com/pricing');">PRICING</a>
                            <!-- <a class="text-black Footer-nav-text" style="font-size:13px;cursor: pointer;" [routerLink]="['/']">BLOG</a> -->
                            <!-- <a class="text-black Footer-nav-text"
                        style="font-size:13px;cursor: pointer;font-family:helvetica;" [routerLink]="['/contactus']"
                        [ngStyle]="{'font-weight': this._router.url==('/contactus') ? 'bold' : 'normal',  'color': this._router.url==('/contactus') ? '#2b2172' : '#000000'}">CONTACT</a> -->
                        </div>
                        <div class="flex justify-end gap-3" style="margin-top:17px;">
                            <a href="https://twitter.com/printingwa/" target="_blank">
                                <img src="./assets/images/twitter_icon.png" class="footer-icons"
                                    style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                            </a>
                            <a href="https://www.instagram.com/printingwarehouses/" class="ml-[12px]" target="_blank">
                                <img src="./assets/images/instagram.png" class="footer-icons"
                                    style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                            </a>
                            <a href="https://www.facebook.com/printingwarehouses/" class="ml-[12px]" target="_blank">
                                <img src="./assets/images/fb_iocn.png" class="footer-icons"
                                    style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                            </a>
                        </div>
                    </div>
                </div>
                <hr style="border-bottom-width:0px;">
                <p class="text-white text-center"
                    style="font-size:14px;font-family: 'Varela Round', sans-serif;opacity:51%;">© 2023 Printing
                    Warehouses
                    of America, Inc. All rights reserved.</p>

            </div>
            <!-- footer session ends -->
            <div class="flex flex-col md:hidden bg-[#000000] p-[50px] w-full" style="justify-content:space-between;">
                <div class="flex w-full">
                    <img class="footer_logo" src="./assets/images/footer_logo.svg" style="width:100px;height:auto;">
                    <div class="flex justify-end" style="justify-content:space-between;align-items:center;width:100%;">
                        <!-- <img class="footer_icons" src="./assets/images/twitter-1.png" style="width:25px;height:25px;">
                <img class="footer_icons ml-[12px]" src="./assets/images/instagram-2.png"
                    style="width:25px;height:25px;">
                <img class="footer_icons ml-[12px]" src="./assets/images/facebook-2.png"
                    style="width:25px;height:25px;"> -->

                        <a href="https://twitter.com/printingwa/" target="_blank">
                            <img src="./assets/images/twitter_icon.png" class="footer-icons"
                                style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        </a>
                        <a href="https://www.instagram.com/printingwarehouses/" class="ml-[12px]" target="_blank">
                            <img src="./assets/images/instagram.png" class="footer-icons"
                                style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        </a>
                        <a href="https://www.facebook.com/printingwarehouses/" class="ml-[12px]" target="_blank">
                            <img src="./assets/images/fb_iocn.png" class="footer-icons"
                                style="width:30px;height:30px;justify-content: center;display: flex;align-items: center;">
                        </a>


                    </div>
                </div>
                <hr style="border-bottom-width:0px;">
                <p class="footer_Text text-center"
                    style="font-size:12px;font-family: 'Varela Round', sans-serif;opacity:51%;color:white;">© 2023
                    Printing
                    Warehouses
                    of America, Inc. All rights reserved.</p>

            </div>
        </div>
    </div>




</div>