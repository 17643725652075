import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { FuseNavigationItem } from '@fuse/components/navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        let nav: Navigation = {
            compact: this.navigationItem,
            default: this.navigationItem,
            futuristic: this.navigationItem,
            horizontal: this.navigationItem
        }
        this._navigation.next(nav);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                // this._navigation.next(this.defaultNavigation);
            })
        );
    }


    navigationItem: FuseNavigationItem[] = [
        {
            id: 'home',
            title: 'HOME',
            type: 'basic',
            link: '/home'
        },
        {
            id: 'products',
            title: 'VIRTUAL BIZ CARDS',
            type: 'basic',
            link: '/products'
        },
        {
            id: 'email_signature',
            title: 'EMAIL SIGNATURES',
            type: 'basic',
            link: '/email-signatures'
        },
        {
            id: 'pricing',
            title: 'PRICING',
            type: 'basic',
            link: 'https://printingwarehouses.com/pricing',
            externalLink: true
        },
        // {
        //     id: 'home',
        //     title: 'Blog',
        //     type: 'basic',
        //     icon: 'heroicons_outline:chart-pie',
        //     link: '/home'
        // },
        // {
        //     id: 'contact',
        //     title: 'CONTACT',
        //     type: 'basic',
        //     link: '/contactus'
        // }
    ];
}
