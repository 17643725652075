import { Component, Input, OnInit } from '@angular/core';
import { Contact } from 'app/core/user/user.types';

import { faPinterestP, faInstagram, faFacebookF, faTwitter, faYoutube, faWhatsapp, faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faGlobe, faLocationDot, faPhone, faTty } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-design1',
  templateUrl: './design1.component.html',
  styleUrls: ['./design1.component.css']
})
export class Design1Component implements OnInit {
  @Input() contactInfo?: Contact;
  constructor() { }

  iconFacebook = faFacebookF
  iconTwitter = faTwitter
  iconInstagram = faInstagram
  iconPinterest = faPinterestP
  iconYouTube = faYoutube
  iconEmail = faEnvelope
  iconPhone = faPhone
  iconLocation = faLocationDot
  iconWeb = faGlobe
  iconTelephone = faTty
  iconWhatsapp = faWhatsapp
  iconLinkedIn = faLinkedinIn

  ngOnInit(): void {
  }

}
