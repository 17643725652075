<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap" rel="stylesheet">
    <style>
        .businesscard {
            background-image: url(./assets/images/1015/bg.jpg);
            background-size: cover;
            background-repeat: no-repeat
        }
    </style>
</head>

<body>
    <section>
        <div class="businesscard"
            style="min-width: 250px; max-width:400px;height:auto;margin:auto;position: relative;">

            <div
                style="width:80%;background-image: url(./assets/images/1015/bg-top.png);background-size:100% 100%;background-repeat:no-repeat;padding:30px 15px;;">
                <img [src]="contactInfo?.company_logo" *ngIf="contactInfo?.company_logo" style="width:80px;height:80px;object-fit:contain;display:block;margin:auto;margin-bottom:3rem;">
                <div  *ngIf="contactInfo?.description_title"
                    style="background-image:url(./assets/images/1015/line.png);background-size:contain;background-repeat:no-repeat;padding:30px 20px 30px 15px;margin-bottom:2.5rem;">
                    <p
                        style="color:white;font-size:40px;font-family:poppins;font-weight:bolder;margin:0px;line-height: 1;">
                        {{contactInfo?.description_title}}</p>
                </div>
                <div style="display:flex;align-items:center;justify-content:center;gap:40px;margin-bottom:2rem;">
                    <div  *ngIf="contactInfo?.phone_number_1" style="text-align:center">
                        <a [href]="'tel:'+ contactInfo?.phone_number_1">
                        <img src="./assets/images/1015/01.png" style="width:35px;height:35px;object-fit:contain;margin: auto;">
                        <p style="color:white;font-family:poppins;font-size:12px;">CALL</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.email" style="text-align:center">
                        <a [href]="'mailto:'+ contactInfo?.email">
                        <img src="./assets/images/1015/02.png" style="width:35px;height:35px;object-fit:contain;margin: auto;">
                        <p style="color:white;font-family:poppins;font-size:12px;">EMAIL</p>
                        </a>
                    </div>
                </div>
                <div style="display:flex;align-items:center;justify-content:center;gap:40px;">
                    <div *ngIf="contactInfo?.whatsapp" style="text-align:center">
                        <a [href]="'https://wa.me/'+contactInfo?.whatsapp">
                        <img src="./assets/images/1015/04.png" style="width:35px;height:35px;object-fit:contain;margin: auto;">
                        <p style="color:white;font-family:poppins;font-size:12px;">WHATSAPP</p>
                        </a>
                    </div>
                    <div *ngIf="contactInfo?.website" style="text-align:center">
                        <a [href]="contactInfo?.website">
                        <img src="./assets/images/1015/03.png" style="width:35px;height:35px;object-fit:contain;margin: auto;">
                        <p style="color:white;font-family:poppins;font-size:12px;">WEB</p>
                        </a>
                    </div>
                </div>
                <div style="display:flex;gap:10px;justify-content:center;align-items:center;margin-top:5rem;">
                    <a [href]="'https://instagram.com/'+contactInfo?.instagram" *ngIf="contactInfo?.instagram">
                    <img src="./assets/images/1015/instagram.png" style="width:20px;height:20px;object-fit:contain">
                    </a>
                    <a [href]="'https://facebook.com/'+contactInfo?.facebook" *ngIf="contactInfo?.facebook">
                    <img src="./assets/images/1015/fb.png" style="width:20px;height:20px;object-fit:contain">
                    </a>
                    <a [href]="'https://twitter.com/'+contactInfo?.twitter" *ngIf="contactInfo?.twitter">
                    <img src="./assets/images/1015/twitter.png" style="width:20px;height:20px;object-fit:contain">
                    </a>
                    <a [href]="'https://linkedin.com/'+contactInfo?.linkedin" *ngIf="contactInfo?.linkedin">
                    <img src="./assets/images/1015/linkedin.png" style="width:20px;height:20px;object-fit:contain">
                    </a>

                </div>

            </div>
        </div>


    </section>

</body>

</html>